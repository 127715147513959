import React, { Component } from 'react';
import { observer } from 'mobx-react';
import TitlePanel from '../../components/layout/TitlePanel';
import FrameWrap from '../../components/layout/FrameWrap';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import { Button, Col, Form, FormGroup, InputGroup } from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import { Add, SearchNormal1 } from 'iconsax-react';
import Styles from '../team/Team.module.scss';
import TeamSelect from '../../components/select/TeamSelect';
import { makeObservable, observable, reaction } from 'mobx';
import AgentSelect from '../../components/select/AgentSelect';
import Select, { components } from 'react-select';
import { DataGridStore, Pagination, Sort } from '@qbot-chat/qbot-uikit/grid';
import appStore from '../../store/AppStore';
import TeamAgentCard from './MemberCard';
import Avatar from '../../components/avatar/Avatar';
import TeamAgentDialog, { teamAgentDialogStore } from './MemberDialog';
import { ACCESS_RIGHTS } from '../../const/AppConst';
import { USER_TYPE } from '@qbot-chat/qbot-uikit';

const memberDataGridStore = new DataGridStore({
  fetchUrl: `/agent/manage/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`team`, Sort.SORT_DIRECTION.DESC),
});

const styles = {
  menu: (base) => ({
    ...base,
    zIndex: 9,
    color: '#6C757D',
  }),
  container: (base) => ({
    ...base,
  }),
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(254 147 21 / 25%)' : 0,
    borderColor: state.isFocused ? '#ffc98a' : '#ced4da',
    minHeight: '0.75rem',
    '&:hover': {
      boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(254 147 21 / 25%)' : 0,
      borderColor: state.isFocused ? '#ffc98a' : '#ced4da',
    },
    zIndex: 1,
    borderRadius: '4px',
  }),

  singleValue: (base) => ({
    ...base,
    color: '#6C757D',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0.1625rem 1rem',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 .25rem',
    transform: `rotate( 180deg )`,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '0 .25rem',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '0 .25rem',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    width: 0,
    margin: '.25rem 0',
  }),
};

const Placeholder = (props) => {
  return (
    <components.Placeholder {...props}>
      <div className={'d-flex justify-content-start'}>
        <SearchNormal1 className={'me-1'} size="24" color="#6C757D" />
        {props.children}
      </div>
    </components.Placeholder>
  );
};

@observer
class MemberManageListView extends Component {
  @observable
  selectedTeam = null;

  @observable
  selectedAgent = null;

  @observable
  keyword = null;

  @observable
  isAgentSelectView = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    reaction(
      () => this.selectedTeam,
      (selectedTeam) => {
        if (selectedTeam) {
          memberDataGridStore.searchCondition.set('team', selectedTeam);
          memberDataGridStore.page = 1;
          memberDataGridStore.fetch();
        } else {
          memberDataGridStore.dataSet.clear();
        }
      },
    );
  }

  componentDidMount() {
    if (!ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type)) {
      this.selectedTeam = appStore.team;
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.selectedTeam &&
      prevProps.pathname &&
      prevProps.pathname.startsWith('/main/member/detail') &&
      this.props.pathname === '/main/member/list'
    ) {
      memberDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    memberDataGridStore.dataSet.clear();
    memberDataGridStore.searchCondition.clear();
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.search();
    }
  };

  search = () => {
    if (this.selectedTeam) {
      memberDataGridStore.searchCondition.set('team', this.selectedTeam);
      memberDataGridStore.searchCondition.set('keyword', this.keyword);
      memberDataGridStore.refresh();
    }
  };

  render() {
    return (
      <FrameWrap className={'w-100'}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <div>
                <TitlePanelLabel label={'팀원 관리'} />
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel className={Styles.Container}>
          <Panel
            className={`rounded-3`}
            style={{ border: '1px solid #E8E8E8' }}
          >
            <div className={Styles.Search}>
              <Col md={3}>
                {ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type) ? (
                  <TeamSelect
                    autoFocus={true}
                    placeholder="팀 선택"
                    onChange={(e) => {
                      if (e && e.object) {
                        this.selectedTeam = e.object;
                      } else {
                        this.selectedTeam = null;
                      }
                    }}
                  />
                ) : (
                  <div className={'d-flex flex-row'}>
                    <Avatar
                      src={appStore.team.avatar.fullUrl}
                      seed={appStore.team.id}
                      type={'team'}
                      width={'2rem'}
                    />
                    <div className={'ms-2 align-self-center'}>
                      {' '}
                      {appStore.team.name}{' '}
                    </div>
                  </div>
                )}
              </Col>
              <Col md={{ span: 8, offset: 1 }}>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    이름, 로그인ID
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="이름, 로그인 아이디 검색"
                    onChange={(e) => {
                      this.keyword = e.target.value;
                    }}
                    onKeyDown={(e) => this.onKeyDown(e)}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={() => this.search()}
                  >
                    검색
                  </Button>
                </InputGroup>
              </Col>
            </div>
          </Panel>
          <div className={Styles.AgentContainer}>
            {this.selectedTeam && appStore.type !== 'AGENT' && (
              <div style={{ whiteSpace: 'nowrap' }}>
                <div
                  className={Styles.AgentSearchBtn}
                  onClick={() =>
                    (this.isAgentSelectView = !this.isAgentSelectView)
                  }
                >
                  <div className={Styles.Add}>
                    <Add size="32" color="#6C757D" />
                  </div>
                  <div className={Styles.Text}>상담원 추가하기</div>
                </div>
                <div
                  className={`${Styles.Select} ${this.isAgentSelectView ? 'd-block' : 'd-none'}`}
                >
                  {this.isAgentSelectView && (
                    <AgentSelect
                      styles={{
                        menu: (base) => ({
                          ...base,
                          marginLeft: 1,
                          zIndex: 12,
                        }),
                        container: (base) => ({
                          ...base,
                          backgroundColor: '#FFFFFF',
                          zIndex: 12,
                        }),
                      }}
                      visible={this.isAgentSelectView}
                      types={['AGENT', 'AGENT_LEADER']}
                      isNotTeamAgent={true}
                      otherComponents={{ Placeholder }}
                      menuIsOpen={true}
                      placeholder={`상담원 검색`}
                      onChange={(e) => {
                        if (e && e.object) {
                          this.selectedAgent = e.object;
                          teamAgentDialogStore.open({
                            agent: this.selectedAgent,
                            teamId: this.selectedTeam.id,
                            rank: this.selectedAgent?.rank,
                            onSave: () => this.search(),
                          });
                          this.isAgentSelectView = false;
                        } else {
                          this.selectedAgent = null;
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {memberDataGridStore.dataSet.items.map((agent) => {
              return (
                <TeamAgentCard
                  key={agent.id}
                  agent={agent}
                  team={this.selectedTeam}
                  store={memberDataGridStore}
                />
              );
            })}
          </div>
          <div className={Styles.Pagination}>
            <div>
              총{' '}
              <span style={{ fontWeight: 400, color: '#007DFC' }}>
                {memberDataGridStore.dataSet.itemsCount}
              </span>
              건
            </div>
            {!memberDataGridStore.noPaging && (
              <div className={'d-flex justify-content-center'}>
                <Pagination store={memberDataGridStore} />
              </div>
            )}
            {!memberDataGridStore.noPaging &&
              memberDataGridStore.isChangeRowsPerPage && (
                <div className="d-flex">
                  <Select
                    className="me-2"
                    menuPlacement={'top'}
                    styles={styles}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                    })}
                    options={[
                      { label: `10 개씩 보기`, value: 10 },
                      { label: `20 개씩 보기`, value: 20 },
                      { label: `50 개씩 보기`, value: 50 },
                    ]}
                    onChange={({ value }) => {
                      memberDataGridStore.changeRowsPerPage(value);
                    }}
                    value={{
                      label: memberDataGridStore.rowsPerPage + ' 개씩 보기',
                      value: memberDataGridStore.rowsPerPage,
                    }}
                  ></Select>
                </div>
              )}
          </div>
          <TeamAgentDialog />
        </Panel>
      </FrameWrap>
    );
  }
}

export default MemberManageListView;
