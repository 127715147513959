import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Button } from 'react-bootstrap';
import TeamSelect from '../../components/select/TeamSelect';
import InfoReport from '../report/InfoReport';
import CountChart from '../../components/chart/CountChart';
import Styles from './Monitor.module.scss';
import axios from 'axios';
import { computed, makeObservable, observable } from 'mobx';
import CommonHelper from '../../helper/CommonHelper';
import ReactApexChart from 'react-apexcharts';
import { AiOutlineRedo } from 'react-icons/ai';
import FrameWrap from '../../components/layout/FrameWrap';
import Panel from '../../components/layout/Panel';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import appStore from '../../store/AppStore';

const TimeMonitor = (props) => {
  const { title, summary, data } = props;
  return (
    <div className={Styles.Panel}>
      <InfoReport title={title} summary={summary} />
      <Row className={'mt-2'}>
        <Col md={6} className={'d-flex flex-column'}>
          <div className={'align-self-center fs-2 fw-bold'}>
            {CommonHelper.timeFormat(data.avg)}
          </div>
          <div className={'align-self-center'}>평균 시간</div>
        </Col>
        <Col md={6} className={'d-flex flex-column'}>
          <div className={'align-self-center fs-2 fw-bold'}>
            {CommonHelper.timeFormat(data.max)}
          </div>
          <div className={'align-self-center'}>최장 시간</div>
        </Col>
      </Row>
      <div
        className={'d-flex justify-content-between mt-4'}
        style={{ fontSize: '12px' }}
      >
        <div>백분률</div>
        <div>평균 시간</div>
      </div>
      <hr className={'border-top border-black mt-1 mb-1'} />
      <div
        className={'d-flex justify-content-between'}
        style={{ fontSize: '12px' }}
      >
        <div>80 %</div>
        <div>{CommonHelper.timeFormat(data.avg80)}</div>
      </div>
      <hr className={'border-top border-black mt-1 mb-1'} />
      <div
        className={'d-flex justify-content-between'}
        style={{ fontSize: '12px' }}
      >
        <div>90 %</div>
        <div>{CommonHelper.timeFormat(data.avg90)}</div>
      </div>
      <hr className={'border-top border-black mt-1 mb-1'} />
      <div
        className={'d-flex justify-content-between'}
        style={{ fontSize: '12px' }}
      >
        <div>95 %</div>
        <div>{CommonHelper.timeFormat(data.avg95)}</div>
      </div>
    </div>
  );
};
@observer
class Monitor extends Component {
  @observable
  selectedTeam = null;

  @observable
  ticketStats = { pendingTotal: 0, activeTotal: 0, idleTotal: 0 };

  @observable
  agentStats = { active: 0, offline: 0, away: 0 };

  @observable
  leaderStats = { active: 0, offline: 0, away: 0 };

  @observable
  todayTicketCount = { created: 0, closed: 0 };

  @observable
  firstAssignedTime = { avg: 0, max: 0, avg80: 0, avg90: 0, avg95: 0 };

  @observable
  firstRespondTime = { avg: 0, max: 0, avg80: 0, avg90: 0, avg95: 0 };

  @observable
  processingTime = { avg: 0, max: 0, avg80: 0, avg90: 0, avg95: 0 };

  @observable
  completeTime = { avg: 0, max: 0, avg80: 0, avg90: 0, avg95: 0 };

  @observable
  closeCount = { agent: 0, admin: 0, customer: 0, system: 0 };

  @observable
  ratingCount = { one: 0, two: 0, three: 0, four: 0, five: 0 };

  @computed
  get closedCount() {
    return {
      options: {
        chart: {
          width: 380,
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        labels: ['상담원', '관리자', '고객', '시스템'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: '총 티켓 수',
                },
              },
            },
          },
        },
      },
      series: [
        this.closeCount.agent,
        this.closeCount.admin,
        this.closeCount.customer,
        this.closeCount.system,
      ],
    };
  }

  @computed
  get ratingsCount() {
    return {
      options: {
        chart: {
          width: 380,
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        labels: ['5점', '4점', '3점', '2점', '1점'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: '총 응답 수',
                },
              },
            },
          },
        },
      },
      series: [
        this.ratingCount.five,
        this.ratingCount.four,
        this.ratingCount.three,
        this.ratingCount.two,
        this.ratingCount.one,
      ],
    };
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.intenrval = setInterval(() => {
      this.load();
    }, 5000);
    this.load();
  }

  componentWillUnmount() {
    clearInterval(this.intenrval);
  }
  load() {
    axios
      .post(`/monitor/search`, {
        teamId: this.selectedTeam ? this.selectedTeam.id : null,
      })
      .then((r) => {
        this.ticketStats = r.data.ticketCount;
        this.agentStats = r.data.agentCount;
        this.leaderStats = r.data.agentLeaderCount;
        this.firstAssignedTime = r.data.firstAssignedTime;
        this.firstRespondTime = r.data.firstRespondTime;
        this.processingTime = r.data.processingTime;
        this.completeTime = r.data.completeTime;
        this.todayTicketCount = r.data.todayTicketCount;
        this.closeCount = r.data.closedCount;
        this.ratingCount = r.data.ratingCount;
      });
  }

  render() {
    return (
      <FrameWrap className={'w-100 overflow-y-auto'}>
        <TitlePanel>
          <Col className={`justify-content-between align-items-center d-flex`}>
            <TitlePanelLabel label={'모니터링'} />
            <div className={'d-flex flex-row align-items-center'}>
              <div>{appStore.now.format('LLLL')}</div>
              <Button
                variant={'outline-secondary'}
                className={'d-flex border-0'}
                onClick={() => this.load()}
              >
                <AiOutlineRedo className={'align-self-center'} />
              </Button>
            </div>
          </Col>
        </TitlePanel>
        <Panel>
          <Col md={2}>
            <TeamSelect
              className={'w-100'}
              placeholder="모든 팀"
              onChange={(e) => {
                if (e && e.object) {
                  this.selectedTeam = e.object;
                } else {
                  this.selectedTeam = null;
                }
                this.load();
              }}
            />
          </Col>
        </Panel>
        <Panel>
          <Panel
            style={{
              border: '1px solid #e3e3e3',
              borderRadius: '1rem',
              marginBottom: '2rem',
            }}
          >
            <div className={'mb-4 fw-bold'}>티켓과 상담원</div>
            <div className={Styles.RealContainer}>
              <div className={Styles.Panel}>
                <InfoReport
                  title={'미배정 티켓'}
                  summary={'어떤 상담원에게도 아직 배정되지 않은 티켓입니다.'}
                />
                <CountChart data={this.ticketStats.pendingTotal} />
              </div>
              <div className={Styles.Panel}>
                <InfoReport
                  title={'상담 중 티켓'}
                  summary={'상담원과 고객이 대화를 주고받고 있는 티켓입니다.'}
                />
                <CountChart data={this.ticketStats.activeTotal} />
              </div>
              <div className={`${Styles.Panel} d-flex flex-column`}>
                <div>상담원</div>
                <div
                  className={
                    'h-100 d-flex flex-column justify-content-around align-self-center'
                  }
                >
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>{this.agentStats.active}</div>
                    <div className={Styles.Name}>온라인</div>
                  </div>
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>{this.agentStats.away}</div>
                    <div className={Styles.Name}>자리비움</div>
                  </div>
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>
                      {this.agentStats.offline}
                    </div>
                    <div className={Styles.Name}>오프라인</div>
                  </div>
                </div>
              </div>
              <div className={`${Styles.Panel} d-flex flex-column`}>
                <div>팀장</div>
                <div
                  className={
                    'h-100 d-flex flex-column justify-content-around align-self-center'
                  }
                >
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>
                      {this.leaderStats.active}
                    </div>
                    <div className={Styles.Name}>온라인</div>
                  </div>
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>{this.leaderStats.away}</div>
                    <div className={Styles.Name}>자리비움</div>
                  </div>
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>
                      {this.leaderStats.offline}
                    </div>
                    <div className={Styles.Name}>오프라인</div>
                  </div>
                </div>
              </div>
              <div className={Styles.Panel}>
                <InfoReport
                  title={'유휴 티켓'}
                  summary={
                    '상담원의 마지막 응답 이후 설정한 시간 동안 해당 고객의 답변이 없는 티켓입니다.'
                  }
                />
                <CountChart data={this.ticketStats.idleTotal} />
              </div>
              <div className={Styles.Panel}>
                <InfoReport
                  title={'상담원당 티켓 수'}
                  summary={
                    '전체 상담 중 티켓 수를 온라인 혹은 자리 비움 상태인 상담원의 총합으로 나눈 수입니다.'
                  }
                />
                <CountChart
                  decimals={2}
                  data={
                    this.ticketStats.activeTotal /
                    (this.agentStats.active +
                      this.agentStats.away +
                      this.leaderStats.active +
                      this.leaderStats.away)
                  }
                />
              </div>
              <div className={Styles.Panel}>
                <InfoReport
                  title={'온라인 상담원 당 티켓 수'}
                  summary={'온라인 상담원 당 평균 상담 중 티켓 수입니다.'}
                />
                <CountChart
                  decimals={2}
                  data={
                    this.ticketStats.activeTotal /
                    (this.agentStats.active + this.leaderStats.active)
                  }
                />
              </div>
            </div>
          </Panel>
          <Panel
            style={{
              border: '1px solid #e3e3e3',
              borderRadius: '1rem',
              marginBottom: '2rem',
            }}
          >
            <div className={'mb-4 fw-bold'}>오늘의 통계</div>
            <div className={Styles.TodayContainer}>
              <TimeMonitor
                title={`최초 배정 시간`}
                summary={`티켓 생성부터 최초 배정까지 걸린 시간입니다.`}
                data={this.firstAssignedTime}
              />
              <TimeMonitor
                title={`최초 응답 시간`}
                summary={`티켓 배정 후 상담원 첫 응답까지 걸린 시간입니다.`}
                data={this.firstRespondTime}
              />
              <TimeMonitor
                title={`티켓 처리 시간`}
                summary={`티켓 배정 후 티켓 종료까지 걸린 시간입니다.`}
                data={this.processingTime}
              />
              <TimeMonitor
                title={`티켓 해결 시간`}
                summary={`티켓 생성부터 티켓 종료까지 걸린 시간입니다.`}
                data={this.completeTime}
              />
              <div className={Styles.Panel}>
                <InfoReport
                  title={'생성 및 종료'}
                  summary={'오늘 생성되거나 종료된 티켓 수입니다.'}
                />
                <div className={'m-4'}>
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>
                      {this.todayTicketCount.created}
                    </div>
                    <div className={Styles.Name}>새 티켓</div>
                  </div>
                  <div className={Styles.Status}>
                    <div className={Styles.Count}>
                      {this.todayTicketCount.closed}
                    </div>
                    <div className={Styles.Name}>종료된 티켓</div>
                  </div>
                </div>
              </div>
              <div className={Styles.Panel}>
                <InfoReport
                  title={'티켓 종료 주체'}
                  summary={'각 역할별 종료한 티켓의 수와 비율을 보여줍니다.'}
                />
                <div className={'d-flex justify-center'}>
                  <ReactApexChart
                    options={this.closedCount.options}
                    series={this.closedCount.series}
                    type={'donut'}
                    width={380}
                  />
                </div>
              </div>
              <div className={Styles.Panel}>
                <InfoReport
                  title={'고객 만족도'}
                  summary={'고객 만족도 점수 별 비율 입니다.'}
                />
                <div className={'d-flex justify-center'}>
                  <ReactApexChart
                    options={this.ratingsCount.options}
                    series={this.ratingsCount.series}
                    type={'donut'}
                    width={380}
                  />
                </div>
              </div>
            </div>
          </Panel>
        </Panel>
      </FrameWrap>
    );
  }
}

export default Monitor;
