import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Styles from './TicketDetailView.module.scss';
import { TICKET_FIELD_KEYS } from '../../const/AppConst';
import moment from 'moment';
import { fromNow } from '../../util/Utils';
import { MdClose } from 'react-icons/md';
import appStore from '../../store/AppStore';
import { Badge, Spinner } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsPinAngle } from 'react-icons/bs';
import Select from 'react-select';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import TextToolTip from '../../components/tootip/TextToolTip';
import StoreMemoDialog from '../customer/StoreMemoDialog';
import { observer } from 'mobx-react';
import { CONTENT_TYPE } from '@qbot-chat/qbot-uikit';
import InnerHtml from './InnerHtml';
import { QtParagraph, QtText } from '../../components/custom/CustomTypography';
import { Flex } from 'antd';

const BranchMemo = ({
  id,
  notes,
  creator,
  createdAt,
  branch,
  type,
  onDelete,
}) => {
  return (
    <span key={id} className={Styles.Memo}>
      <div className={`w-100`}>
        <TextToolTip text={notes} lines={3} />
        <span className={Styles.MemoInfo}>
          <span style={{ color: '#2C8DFF' }} className={`pe-2`}>
            {fromNow(createdAt)}
          </span>
          <span style={{ color: '#17A2B8' }} className={`pe-2`}>
            {creator?.name}
          </span>
          <span>{branch?.name}</span>
        </span>
      </div>
      {(type === 'pin' || type === 'pin_brand') && <BsPinAngle size={24} />}
      {type !== 'pin' && type !== 'pin_brand' && appStore.id === creator.id && (
        <a className={Styles.Remove} onClick={() => onDelete(id)}>
          <MdClose />
        </a>
      )}
    </span>
  );
};

const Card = ({ header, children, footer, subHeader }) => {
  return (
    <div className={Styles.CardWrap}>
      <div className={Styles.Card}>
        <div className={Styles.CardHeader}>{header}</div>
        {subHeader && <div className={`w-100 pe-3`}>{subHeader}</div>}
        <div className="flex-default overflow-auto w-100">
          <div className={Styles.CardContent}>{children}</div>
        </div>
      </div>
      {footer && <div>{footer}</div>}
    </div>
  );
};

// const Record = ({ label, value }) => (
//   <div className={Styles.Record}>
//     <div className={Styles.Label}>{label}</div>
//     <div className={Styles.Value}>{value}</div>
//   </div>
// );

const TicketDetailView = observer(({ store, ticket, historyTickets }) => {
  const customerRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [value, setValue] = useState('point');
  const [customerMemos, setCustomerMemos] = useState([]);
  const [branchMemos, setBranchMemos] = useState([]);
  const [branch, setBranch] = useState(null);
  const [branchMemo, setBranchMemo] = useState('');
  const [customerMemo, setCustomerMemo] = useState('');
  const [customerSummary, setCustomerSummary] = useState('');
  const [size, setSize] = useState([]);
  // const brandId = ticket?.properties['brand-id'] || 'EM';
  const customerId = ticket.customer.id;
  const customFields = ticket?.properties || {};
  const branchId = customFields[TICKET_FIELD_KEYS.branchCd] || '0001';
  // const inflowChannelId = customFields[TICKET_FIELD_KEYS.chnlId];
  const [lostItemModalOpen, setLostItemModalOpen] = useState(false);
  const [notices, setNotices] = useState([]);
  const customerOptions = () => {
    return customers.map((customer) => ({
      label: `${customer.cust_name}${
        customer.home_address ? ` / ${customer.home_address}` : ''
      }`,
      value: customer.cust_id,
    }));
  };

  const handleNewBranchMemoChange = (e) => {
    setBranchMemo(e.target.value);
  };

  const handleBranchMemoKeyDown = async (e) => {
    if (e.key === 'Enter' && !e.nativeEvent?.isComposing && branchMemo) {
      await handleSaveBranchMemo();
    }
  };

  const handleSaveBranchMemo = async () => {
    await axios.post('/branch/memo/write', {
      branch: { id: branchId },
      notes: branchMemo,
    });
    setBranchMemo('');
    loadBranchMemos();
  };

  const handleDeleteBranchMemo = async (id) => {
    await axios.post(`/branch/memo/delete/${id}`);
    loadBranchMemos();
  };

  const loadBranchMemos = () => {
    axios.post(`/branch/memos/${branchId}`).then(({ data: memoResponse }) => {
      setBranchMemos(memoResponse);
    });
  };

  const handleNewCustomerMemoChange = (e) => {
    setCustomerMemo(e.target.value);
  };

  const handleCustomerMemoKeyDown = async (e) => {
    if (e.key === 'Enter' && !e.nativeEvent?.isComposing && customerMemo) {
      await handleSaveCustomerMemo();
    }
  };

  const handleSaveCustomerMemo = async () => {
    await axios.post('/customer/memo/write', {
      userId: customerId,
      notes: customerMemo,
    });
    setCustomerMemo('');
    loadCustomerMemos();
  };

  const handleDeleteCustomerMemo = async (id) => {
    await axios.post(`/customer/memo/delete/${id}`);
    loadCustomerMemos();
  };

  const loadCustomerMemos = () => {
    axios
      .post(`/customer/memos`, { userId: customerId })
      .then(({ data: memoResponse }) => {
        setCustomerMemos(memoResponse);
      });
  };

  // const loadCustomerSummary = () => {
  //   let currentDate = moment().format('YYYY-MM-DD');
  //   setCustomerSummary('');
  //   axios
  //     .post(`/customer/summary`, {
  //       sendbird_id: customerId,
  //       issuedat: currentDate,
  //     })
  //     .then(({ data: memoResponse }) => {
  //       setCustomerSummary(memoResponse);
  //     });
  // };

  // const handleChange = (event, newValue) => {
  //   appStore.eventTrackFn?.('event', TICKET_DETAIL_TABS[newValue]);
  //   setValue(newValue);
  //   setLostItemModalOpen(false);
  // };

  useEffect(() => {
    axios
      .get('/notice/active/list/' + '10')
      .then((res) => setNotices(res.data));
  }, []);
  useEffect(() => {
    setValue('point');

    customerRef?.current?.clearValue();
    if (ticket?.customer.key) {
      setIsLoading(true);
      // API 개선으로 수정이 들어간 부분
      axios
        .post('/customer/of/ticket', ticket.customer)
        .then(({ data: customerResponse }) => {
          setCustomers(customerResponse);
          setCustomer(customerResponse[0]);
        })
        .finally(() => setIsLoading(false));
    }

    if (customerId) {
      loadCustomerMemos();
      //loadCustomerSummary();
    }

    if (branchId) {
      // FIXME: 점포코드 필요

      loadBranchMemos();

      axios
        .post(`/branch/info/${branchId}`)
        .then(({ data: branchInfoResponse }) => {
          setBranch(branchInfoResponse);
        });
    }

    setLostItemModalOpen(false);
  }, [ticket]);

  // const formattedPhone = customer?.cust_id
  //   ? customer?.cust_id?.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
  //   : '';
  // const lastHistoryTicket =
  //   historyTickets && historyTickets.length > 0 ? historyTickets[0] : null;
  // const lastCustomFields = lastHistoryTicket?.properties || {};
  // const lastRating = lastCustomFields[TICKET_FIELD_KEYS.customerRating];
  // const lastComment = lastCustomFields[TICKET_FIELD_KEYS.customerComment];
  // const lastClosedAt = lastHistoryTicket?.closedAt
  //   ? moment(lastHistoryTicket?.closedAt).format('YYYY-MM-DD')
  //   : '';
  // const branchHolidays = branch?.branchHolidays
  //   ?.reduce((acc, holidays) => {
  //     const {
  //       holiday_day1,
  //       holiday_day2,
  //       holiday_day3,
  //       holiday_month,
  //       holiday_year,
  //     } = holidays;
  //     const days = [holiday_day1, holiday_day2, holiday_day3]
  //       .filter((v) => !!v)
  //       .sort((a, b) => parseInt(a) - parseInt(b))
  //       .map((day) =>
  //         moment(`${holiday_year}${holiday_month}${day}`).format('MM/DD(dd)'),
  //       );
  //     return acc.concat(days);
  //   }, [])
  //   ?.join(',');

  const onSendRecommendMessge = async (e) => {
    e.preventDefault();
    const contentType = CONTENT_TYPE.TEXT;
    const message = {
      content: {
        type: contentType,
        value: e.target[0].value,
      },
      contentType,
      createdAt: moment(),
    };
    store.onSendMessage(message);
  };

  return (
    <div className={Styles.TicketDetail}>
      <SplitPane
        split="horizontal"
        onChange={(size) => setSize(size)}
        primary="second"
      >
        {/* <Pane initialSize={size[0]} minSize="5%">
          <div className={`h-100 overflow-auto bg-white`}>
            <div className={Styles.TabContainer}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="tab"
                    variant="fullWidth"
                  >
                    <Tab
                      label="포인트/영수증"
                      value="point"
                      className={Styles.TabTitle}
                    />
                    {brandId == 'TR' && (
                      <Tab
                        label="멤버십메뉴얼"
                        value="membership"
                        className={Styles.TabTitle}
                      />
                    )}
                    {brandId == 'EM' && (
                      <Tab
                        label="e-Money"
                        value="emoney"
                        className={Styles.TabTitle}
                      />
                    )}
                    <Tab
                      label="점포"
                      value="store"
                      className={Styles.TabTitle}
                    />
                    <Tab
                      label="재고"
                      value="stock"
                      className={Styles.TabTitle}
                    />
                    <Tab
                      label="분실물"
                      value="lost"
                      disabled
                      className={Styles.TabTitle}
                    />
                    {brandId == 'TR' && (
                      <Tab
                        label="로드쇼"
                        value="roadshow"
                        className={Styles.TabTitle}
                      />
                    )}
                  </TabList>
                </Box>
                <TabPanel value="point">
                  <PointTab customer={customer} />
                </TabPanel>
                <TabPanel value="emoney">
                  <EmoneyTab customer={customer} />
                </TabPanel>
                <TabPanel value="store">
                  <StoreTab
                    branchId={branchId}
                    currentTextareaChange={currentTextareaChange}
                    ticket={ticket}
                  />
                </TabPanel>
                <TabPanel value="stock">
                  <StockTab
                    branchId={branchId}
                    ticketId={ticket?.id}
                    userId={appStore.username}
                    onClose={() => {}}
                    inflowChannelId={inflowChannelId}
                  />
                </TabPanel>
                <TabPanel value="lost">
                  <LostTab
                    branchId={branchId}
                    setModalOpen={setLostItemModalOpen}
                    modalOpen={lostItemModalOpen}
                  />
                </TabPanel>
                <TabPanel value="membership" className={`p-0`}>
                  <MembershipTab />
                </TabPanel>
                <TabPanel value="roadshow" className={`p-0`}>
                  <RoadshowTab
                    branchId={branchId}
                    currentTextareaChange={currentTextareaChange}
                    ticket={ticket}
                  />
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </Pane> */}
        <Pane initialSize={size[0]} minSize="300px">
          <div className={Styles.Header}>
            {/* {customer?.cust_id}님의 상세정보 */}
            사용자 정의 영역
          </div>
        </Pane>
        <Pane initialSize={size[1]} minSize="280px">
          <div className={`h-100 overflow-auto`}>
            <div className={Styles.CardRow}>
              <Card header="시나리오"></Card>
              <Card
                header="기본 정보(메모)"
                subHeader={
                  customers.length > 1 ? (
                    <Select
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      ref={customerRef}
                      isLoading={isLoading}
                      isDisabled={customers.length === 0}
                      isSearchable={true}
                      isClearable={true}
                      name="color"
                      value={customerOptions()?.find(
                        (option) => option.value === customer?.cust_id,
                      )}
                      options={customerOptions()}
                      onChange={(e) => {
                        if (e) {
                          setCustomer(
                            customers.find(
                              (customer) => customer.cust_id === e.value,
                            ),
                          );
                        } else {
                          setCustomer(null);
                        }
                      }}
                      placeholder={'고객 선택'}
                    />
                  ) : null
                }
                footer={
                  <div className={Styles.Input}>
                    <input
                      maxLength={250}
                      type="text"
                      className={Styles.InputInner}
                      placeholder="메세지를 입력해 주세요.(최대250자)"
                      value={customerMemo}
                      onChange={handleNewCustomerMemoChange}
                      onKeyDown={handleCustomerMemoKeyDown}
                    />
                    <button
                      type="button"
                      disabled={!customerMemo}
                      className={`${Styles.SendIconWrap} ${
                        customerMemo ? Styles.Active : ''
                      }`}
                      onClick={handleSaveCustomerMemo}
                    >
                      <AiOutlineEdit size={21} />
                    </button>
                  </div>
                }
              >
                <div className={'w-100 overflow-hidden'}>
                  {/* <div className={`d-flex`}>
                    <TextToolTip
                      text={
                        <>
                          <span className={`pe-1`}>
                            <Badge bg={customer ? 'warning' : 'secondary'}>
                              P
                            </Badge>
                          </span>
                          <span className={`pe-1`}>
                            {customer?.cust_name || '-'}
                          </span>
                          <span style={{ color: '#2C8DFF' }} className={`pe-1`}>
                            {formattedPhone}
                          </span>
                          <span>{customer?.home_address || '-'}</span>
                        </>
                      }
                    />
                  </div>
                  <div className={`d-flex pb-1`}>
                    <TextToolTip
                      text={
                        <>
                          <span className={`pe-1`}>
                            <span className={Styles.LocationBadge}>
                              <span>
                                {branch?.name
                                  ? `${branch.name}·${branch.id}`
                                  : '-'}
                              </span>
                            </span>
                          </span>
                          <span className={`pe-1`}>
                            <Badge bg={'secondary'}>영업시간</Badge>
                          </span>
                          <span>{branch?.shopping_time || '-'}</span>
                          <br />
                          <span className={`pe-1 ps-1`}>
                            <Badge bg={'danger'}>휴점</Badge>
                          </span>
                          <span className={Styles.Holidays}>
                            {branchHolidays ? branchHolidays : '-'}
                          </span>
                        </>
                      }
                    />
                  </div>
                  {appStore.isAdmin && lastRating && (
                    <div className={`d-flex`}>
                      <TextToolTip
                        text={
                          <>
                            <span className={`pe-1`}>
                              <Badge bg={'primary'}>{lastRating}</Badge>
                            </span>
                            {lastClosedAt && (
                              <span
                                className={`pe-1`}
                                style={{ color: '#AAAAAA' }}
                              >
                                {lastClosedAt}
                              </span>
                            )}
                            {lastComment && <span>{lastComment}</span>}
                          </>
                        }
                      />
                    </div>
                  )} */}
                  <ul className={Styles.MemoWrap}>
                    {customerMemos.map(({ id, notes, createdAt, creator }) => (
                      <li key={id} className={Styles.Memo}>
                        <div className={Styles.MemoInfo}>
                          <QtText
                            className={Styles.MemoName}
                            ellipsis
                            isCaption
                          >
                            {creator?.name}
                          </QtText>
                          <QtText className={Styles.MemoAt} isCaption>
                            {moment(createdAt).format(`LL`)}
                          </QtText>
                          {appStore.id === creator.id && (
                            <MdClose
                              className={Styles.Remove}
                              onClick={() => handleDeleteCustomerMemo(id)}
                            />
                          )}
                        </div>
                        <QtParagraph
                          className={Styles.MemoContent}
                          ellipsis={{ rows: 3 }}
                        >
                          {notes}
                        </QtParagraph>
                      </li>
                    ))}
                  </ul>
                  {appStore.includeLabsUser && (
                    <div className="d-flex flex-column">
                      {customerSummary ? (
                        <>
                          <span className={`pe-1`}>
                            <Badge bg={'secondary'}>ChatGPT</Badge>
                          </span>
                          <span key="summary" className={Styles.Memo}>
                            <div>
                              <span
                                style={{ color: '#2C8DFF' }}
                                className={`pe-2`}
                              >
                                {customerSummary.summary}
                              </span>
                            </div>
                          </span>
                        </>
                      ) : (
                        <Spinner animation="border" variant="secondary" />
                      )}
                    </div>
                  )}
                </div>
              </Card>

              {/* <Card header="공지사항">
                <ul className={Styles.NoticeList}>
                  {notices.map((v) => (
                    <li key={v.updatedAt}>
                      <span className={Styles.NoticeTitle}>{v.title}</span>
                      <span className={Styles.NoticeInfo}>
                        <span>{v.updater}</span>
                        <span>{moment(v.updatedAt).format(`LL`)}</span>
                      </span>
                      <p className={Styles.NoticeContent}>{v.content}</p>
                      {(v?.file1 || v?.file2) && (
                        <div className={Styles.NoticeLinks}>
                          {v.file1 && (
                            <DownloadLink file={v.file1} name={v.file1Name} />
                          )}
                          {v.file2 && (
                            <DownloadLink file={v.file2} name={v.file2Name} />
                          )}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </Card> */}
              {/* 뭐지?  */}
              {/* <Card
              footer={
                <div className={Styles.Input}>
                  <input
                    type="text"
                    className={Styles.InputInner}
                    placeholder="메세지를 입력해 주세요"
                    value={branchMemo}
                    onChange={handleNewBranchMemoChange}
                    onKeyDown={handleBranchMemoKeyDown}
                  />
                  <button
                    type="button"
                    disabled={!branchMemo}
                    className={`${Styles.SendIconWrap} ${
                      branchMemo ? Styles.Active : ''
                    }`}
                    onClick={handleSaveBranchMemo}
                  >
                    <AiOutlineEdit size={21} />
                  </button>
                </div>
              }
              >
                {branchMemos
                  ?.filter(
                    (branchMemo) =>
                      branchMemo.type === 'pin' ||
                      branchMemo.type === 'pin_brand',
                  )
                  .map((branchMemo, k) => (
                    <BranchMemo
                      key={k}
                      {...branchMemo}
                      onDelete={() => handleDeleteBranchMemo(branchMemo.id)}
                    />
                  ))}
                {branchMemos
                  ?.filter(
                    (branchMemo) =>
                      branchMemo.type !== 'pin' &&
                      branchMemo.type !== 'pin_brand',
                  )
                  .map((branchMemo, k) => (
                    <BranchMemo
                      key={k}
                      {...branchMemo}
                      onDelete={() => handleDeleteBranchMemo(branchMemo.id)}
                    />
                  ))}
              </Card> */}
            </div>
          </div>
        </Pane>
      </SplitPane>
      <StoreMemoDialog />
    </div>
  );
});

TicketDetailView.propTypes = {};

export default TicketDetailView;
