import React from 'react';
import Styles from './OutgoingMessage.module.scss';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { ArrowRotateRight, Back, CloseCircle } from 'iconsax-react';
import Message from './Message.js';
import MessageContent from './MessageContent.js';

@observer
class OutgoingMessage extends React.Component {
  replyMessage(message) {
    let { store } = this.props;
    store.replyMessage = message;
  }

  render() {
    let { message, store } = this.props;
    let {
      createdAt,
      isSent = true,
      isSending = false,
      focus = false,
      notConfirmCount = 0,
      replyMessage,
      content,
    } = message;
    return (
      <div
        className={`${Styles.Container} ${focus ? Styles.Focus : ''}`}
        onAnimationEnd={() => {
          store.messageMap.set(message.id, { ...message, focus: false });
        }}
      >
        <div className={Styles.Content}>
          <div className={Styles.ContentInner}>
            <div className={Styles.MessageOption}>
              {!isSending && isSent && store.channel.status !== 'CLOSED' && (
                <Button
                  variant={'secondary'}
                  size={'sm'}
                  className={Styles.Button}
                >
                  <Back
                    className={Styles.Icon}
                    onClick={() => {
                      this.replyMessage(message);
                    }}
                  />
                </Button>
              )}
              {isSending ? (
                <div className={Styles.Resending}>
                  <Spinner animation="border" variant="secondary" size="sm" />
                </div>
              ) : (
                !isSent && (
                  <div className={Styles.Resending}>
                    <Button
                      variant={'secondary'}
                      size={'sm'}
                      className={Styles.ResendButton}
                      onClick={() => {
                        store.openResendDialog(message);
                      }}
                    >
                      <ArrowRotateRight />
                      <CloseCircle color={`var(--bs-danger)`} />
                    </Button>
                  </div>
                )
              )}

              {
                notConfirmCount > 0 && (
                  <div className={Styles.NotConfirmCount}>
                    {notConfirmCount}
                  </div>
                )
                // :
                // <MdDoneAll color={'#009688'}/>
              }
            </div>
            <Message isIncoming={false} store={store}>
              <MessageContent
                content={content}
                messageKey={message.id}
                store={store}
                isIncoming={false}
                replyMessage={replyMessage}
              />
            </Message>
          </div>
          <div className={Styles.Date}>{moment(createdAt).format('LT')}</div>
        </div>
      </div>
    );
  }
}

export default OutgoingMessage;
