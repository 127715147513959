import {
  action,
  computed,
  entries,
  makeObservable,
  observable,
  reaction,
  values,
} from 'mobx';

import DataSet from './DataSet.js';
import Sort from './Sort.js';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import CommonHelper from '../helper/CommonHelper.js';

/**
 * 데이터 그리드 저장소
 */
class DataGridStore {
  defaultWidth = { min: 60, current: 100, max: 200 };

  /**
   * 데이터그리드의 데이터셋.
   * @type {DataSet}
   */
  @observable dataSet = new DataSet();

  /**
   * 현재 페이지
   * @type {number}
   */
  @observable page = 1;

  @computed
  get parameterizeSort() {
    return this.sort;
  }

  @computed
  get searchConditionWithPage() {
    let m = {};
    this.searchCondition.forEach((v, k) => {
      if (/\./.test(k)) {
        this.assignKeysValue(m, this.searchCondition, k.split(/\./), v);
      } else {
        m[k] = v;
      }
    });
    m.pagingOption = {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
      sort: this.parameterizeSort,
    };
    if (this.fetchUrl.includes('call')) {
      m.companyId = 210;
    }
    return m;
  }

  /**
   * 현재 검색 조건
   * @type {Map}
   */
  @observable searchCondition = new Map();

  /**
   * 정렬 컬럼
   * @type Sort
   */
  @observable sort;

  @observable isFetching = false;

  /**
   * 조회 method
   */
  method;

  /**
   * 조회 url
   */
  fetchUrl;

  /**
   * 응답에서 결과 골라내는 함수
   */
  selectFnFromResponse;

  /**
   * 페이징 블럭 사이즈
   * @type {number}
   */
  paginationSize;

  /**
   * 페이지당 행 수
   * @type {number}
   */
  @observable
  rowsPerPage = 10;

  @observable
  noPaging = false;

  @observable
  noHeader = false;

  @observable
  isChangeRowsPerPage = true;

  /**
   * 현재 정렬 컬럼에 반응하도록 구현 (페이징 없을때 )
   * @returns {unknown[]}
   */
  @computed
  get internalSortedItems() {
    return _.orderBy(
      this.dataSet.items,
      [this.sort?.id],
      [this.sort?.direction?.toLowerCase()],
    );
  }

  @computed
  get isAllChecked() {
    if (
      values(this.dataSet.statusMap).filter(
        (statusMap) => statusMap.get('status')?.isChecked === false,
      ).length === 0
    )
      return true;
    else
      return values(this.dataSet.statusMap).filter(
        (statusMap) => statusMap.get('status')?.isChecked === false,
      ).length > 0
        ? false
        : true;
  }

  @computed
  get checkedRowEntries() {
    const itemEntries = entries(this.dataSet.itemMap);
    const ks = entries(this.dataSet.statusMap)
      .filter(([, statusMap]) => statusMap?.get('status')?.isChecked)
      .map(([k]) => k);
    return itemEntries.filter(([key]) => ks.find((k) => k === key));
  }

  @computed
  get uncheckedRowEntries() {
    const itemEntries = entries(this.dataSet.itemMap);
    const ks = entries(this.dataSet.statusMap)
      .filter(([, status]) => !status.isChecked)
      .map(([k]) => k);

    return itemEntries.filter(([key]) => ks.find((k) => k === key));
  }

  @computed
  get checkedRowCount() {
    return this.dataSet.items.filter((row) => row.isChecked).length;
  }

  /**
   * 행을 선택 토글
   * @param rowId
   */
  toggleCheckedRows(row, checked) {
    let key = row[this.primaryKey];
    let statusMap = this.dataSet.statusMap.get(key) || new Map();
    let status = statusMap.get('status') || {};
    status = {
      ...status,
      isChecked: checked,
    };
    statusMap.set('status', status);
    this.dataSet.statusMap.set(key, statusMap);
  }

  toggleModifiable(row) {
    let key = row[this.primaryKey];
    let statusMap = this.dataSet.statusMap.get(key) || new Map();
    let status = statusMap.get('status') || {};
    status = { ...status, isModifiable: !status.isModifiable };
    statusMap.set('status', status);
    this.dataSet.statusMap.set(key, statusMap);
  }

  toggleDeleted(row, isAdded = false) {
    let key = row[this.primaryKey];
    let originMap = this.dataSet.originMap;
    let itemMap = this.dataSet.itemMap;
    let statusMap = this.dataSet.statusMap.get(key);
    let status = statusMap.get('status') || {};
    if (isAdded) {
      statusMap.delete(key);
      originMap.delete(key);
      itemMap.delete(key);
    } else {
      status = { ...status, isDeleted: !status.isDeleted };
      statusMap.set('status', status);
      this.dataSet.statusMap.set(key, statusMap);
    }
  }

  toggleAllRows(isChecked) {
    entries(this.dataSet.statusMap).map((statusArray) => {
      let statusMap = statusArray[1];
      let status = statusMap.get('status');
      status = { ...status, isChecked };
      statusMap.set('status', status);
      this.dataSet.statusMap.set(statusArray[0], statusMap);
    });
  }

  /**
   * 생성자.
   * @param fetchUrl {string} 조회url
   * @param selectFnFromResponse 응답에서 결과 골라내는 함수
   * @param onPageButtonClick 페이징 버튼 클릭시 동작
   * @param paginationSize {number} 페이징 블럭사이즈
   * @param rowsPerPage {number} 페이지당 행 수
   * @param sort  정렬기준 컬럼.
   * @param itemsFromResponse 각 row 변환함수
   * @param noPaging 페이징없이
   * @param isChangeRowsPerPage 각 row 편집기능 활성화여부
   * @param primaryKey 식별자 프로퍼티
   */
  constructor({
    fetchUrl,
    selectFnFromResponse = (res) => res,
    itemsFromResponse = this.itemsFromResponse,
    onPageButtonClick = this.onPageButtonClick,
    paginationSize = 10,
    rowsPerPage = 10,
    sort = new Sort('sort', Sort.SORT_DIRECTION.ASC),
    noPaging = false,
    noHeader = false,
    isChangeRowsPerPage = true,
    primaryKey = 'tempId',
  }) {
    this.fetchUrl = fetchUrl;
    this.selectFnFromResponse = selectFnFromResponse;
    this.paginationSize = paginationSize;
    this.rowsPerPage = rowsPerPage;
    this.sort = sort;
    this.itemsFromResponse = itemsFromResponse.bind(this);
    this.onPageButtonClick = onPageButtonClick.bind(this);
    this.noPaging = noPaging;
    this.noHeader = noHeader;
    this.isChangeRowsPerPage = isChangeRowsPerPage;
    this.primaryKey = primaryKey;
    makeObservable(this);
  }

  /**
   * fetchUrl 로 items 를 조회한다.
   */
  @action.bound
  fetch() {
    const axiosInstance = axios.create({
      baseURL: `/pds`,
      withCredentials: true,
    });
    this.isFetching = true;
    if (this.fetchUrl.includes('call')) {
      axiosInstance
        .post(this.fetchUrl, this.searchConditionWithPage)
        .then((response) => {
          this.success(response);
        })
        .catch((e) => console.log(e))
        .finally(() => (this.isFetching = false));
    } else {
      axios
        .post(this.fetchUrl, this.searchConditionWithPage)
        .then((response) => {
          this.success(response);
        })
        .catch((e) => console.log(e))
        .finally(() => (this.isFetching = false));
    }
  }

  success(response) {
    let { items, rowsCount } = this.selectFnFromResponse(response);
    let store = this;
    let result = this.itemsFromResponse(items).map((item, idx) => {
      let tempId = uuid();
      return [
        tempId,
        {
          ...item,
          index: (store.page - 1) * store.rowsPerPage + idx + 1,
          tempId,
          sort: idx + 1,
        },
      ];
    });
    let statuses = result.map(([, item]) => {
      let map = new Map();
      let origin = this.dataSet.statusMap.get(item.tempId);
      if (origin) {
        map.set('refMap', origin.get('refMap'));
        map.set('validationMap', origin.get('validationMap'));
      }
      if (item.phoneNumber) {
        item.phoneNumber = `=""${item.phoneNumber}""`;
      }
      map.set('status', {
        isChecked: false,
        isModifiable: false,
        isModified: false,
        isDeleted: false,
        isDisabled: false,
        isAdded: false,
      });
      return [item.tempId, map];
    });
    this.dataSet.statusMap.replace(statuses);
    this.dataSet.originMap = new Map(result);
    this.dataSet.itemMap.replace(result);
    this.dataSet.itemsCount = rowsCount;
  }

  refresh() {
    this.page = 1;
    this.fetch();
  }

  /**
   * 조회결과로 받은 결과 배열 오브젝트에 대한 변환함수.
   * @param item {Object}
   */
  itemsFromResponse(res) {
    return res;
  }

  @action.bound
  changePage(page) {
    this.page = page;
    this.fetch();
  }

  onPageButtonClick(page) {
    this.changePage(page);
  }

  /**
   * 페이지 변경에 대한 기본동작
   */
  subscribeChangePage() {
    reaction(
      () => this.page,
      () => this.fetch(),
    );
  }

  /**
   * 페이지당 행 수 변경에 따른 기본동작
   */
  changeRowsPerPage(rowsPerPage) {
    this.rowsPerPage = rowsPerPage;
    this.refresh();
  }

  /**
   * 계산된 페이지 목록
   * @returns {Array}
   */
  @computed
  get pages() {
    return CommonHelper.range(this.paginationBegin, this.paginationEnd + 1);
  }

  /**
   * 페이지 - 1
   * @returns {number}
   */
  get zeroBasePageIndex() {
    return this.page - 1;
  }

  /**
   * 끝 페이지
   * @returns {number}
   */
  get endPage() {
    return (
      Math.floor(this.dataSet.itemsCount / this.rowsPerPage) +
      (this.dataSet.itemsCount % this.rowsPerPage === 0 ? 0 : 1)
    );
  }

  /**
   * 페이징 블럭 첫 페이지
   * @returns {number}
   */
  get paginationBegin() {
    return (
      Math.floor(this.zeroBasePageIndex / this.paginationSize) *
        this.paginationSize +
      1
    );
  }

  /**
   * 페이징 블럭 끝 페이지
   * @returns {number}
   */
  get paginationEnd() {
    const mayBlockPageEnd = this.paginationBegin + this.paginationSize - 1;

    return mayBlockPageEnd > this.endPage ? this.endPage : mayBlockPageEnd;
  }

  clear() {
    this.dataSet.clear();
  }

  @observable
  colMap = new Map();

  @action.bound
  onResize = (id, size) => {
    let data = this.colMap.get(id);
    this.colMap.set(id, { ...data, current: size.width });
  };

  getNoDataWidth({ isCheckable, visibleCol }) {
    let width = isCheckable ? 16 : 0;
    visibleCol.forEach((col) => {
      width += this.colMap.get(col.props.id)?.current + 16;
    });
    return width - 16;
  }

  assignKeysValue(obj, m, keys, value) {
    let [head, ...tail] = keys;
    let nextObj = obj;
    let key = head;
    let rest = tail;
    while (rest.length > 0) {
      let found = nextObj[key];
      if (!found) {
        let newObj = {};
        nextObj[key] = newObj;
        nextObj = newObj;
      } else {
        nextObj = found;
      }
      let [nextHead, ...nextTail] = rest;
      rest = nextTail;
      key = nextHead;
    }
    nextObj[key] = value;
  }
}

export default DataGridStore;
