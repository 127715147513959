import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { navigate } from '../../helper/HistoryHelper';
import { Button, Col, Form, FormGroup, InputGroup } from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import FrameWrap from '../../components/layout/FrameWrap';
import { makeObservable, observable } from 'mobx';
import _ from 'lodash';
import appStore from '../../store/AppStore';
import { USER_TYPE } from '@qbot-chat/qbot-uikit';

const noticeDataGridStore = new DataGridStore({
  fetchUrl: `/notice/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
});

@observer
class NoticeListView extends Component {
  @observable
  searchKeyword = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    noticeDataGridStore.refresh();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pathname &&
      prevProps.pathname.startsWith('/main/notice/detail') &&
      this.props.pathname === '/main/notice/list'
    ) {
      noticeDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    noticeDataGridStore.searchCondition.clear();
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      noticeDataGridStore.page = 1;
      this.search();
    }
  };

  search = () => {
    let obj = Object.fromEntries(noticeDataGridStore.searchCondition);
    noticeDataGridStore.searchCondition.set('title', this.selectedCompany);
    noticeDataGridStore.searchCondition.set('content', this.searchKeyword);

    if (
      !_.isEqual(obj, Object.fromEntries(noticeDataGridStore.searchCondition))
    ) {
      noticeDataGridStore.refresh();
    } else {
      noticeDataGridStore.fetch();
    }
  };

  render() {
    const { AGENT, AGENT_BOT, AGENT_LEADER } = USER_TYPE;
    const isAgent = [AGENT, AGENT_BOT, AGENT_LEADER].includes(appStore.type);
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'공지사항'} />
              {!isAgent && (
                <Button
                  variant={'outline-primary'}
                  onClick={() => {
                    navigate(`/main/notice/new`);
                  }}
                >
                  + 공지사항 추가
                </Button>
              )}
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Panel
            className={`rounded-3`}
            style={{ border: '1px solid #E8E8E8' }}
          >
            <FormGroup>
              <Col className={`d-flex flex-column`}>
                <div className={'d-flex'}>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        제목, 내용
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="제목 또는 내용으로 검색"
                        value={this.searchKeyword || ''}
                        onChange={(e) => {
                          this.searchKeyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={'outline-secondary'}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className={'flex-default'}>
          <DataGrid
            store={noticeDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`index`}
                key={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,

              <Header
                id={`title`}
                key={`title`}
                name={`제목`}
                width={{ min: 100, current: 300, max: 400 }}
              />,
              <Header
                id={`content`}
                key={`content`}
                name={`내용`}
                width={{ min: 100, current: 350, max: 500 }}
              />,
              <Header
                id={`startedAt`}
                key={`startedAt`}
                name={`시작일`}
                className={`justify-content-end`}
                width={{ min: 80, current: 120, max: 150 }}
                type="date"
              />,
              <Header
                id={`endedAt`}
                key={`endedAt`}
                name={`종료일`}
                className={`justify-content-end`}
                width={{ min: 80, current: 120, max: 150 }}
                type="date"
              />,
              <Header
                id={`updater`}
                key={`updater`}
                name={`등록자`}
                width={{ min: 100, current: 220, max: 250 }}
              />,
              <Header
                id={`updatedAt`}
                key={`updatedAt`}
                name={`수정 일시`}
                className={`justify-content-end`}
                width={{ min: 80, current: 120, max: 150 }}
                type="date"
              />,
            ]}
            onRowClick={(rowData) => {
              !isAgent
                ? navigate(`/main/notice/detail/${rowData.id}`)
                : navigate(`/main/notice/read/${rowData.id}`);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export { NoticeListView as default, noticeDataGridStore };
