import React from 'react';
import { observer } from 'mobx-react';
import Styles from './StatusBar.module.scss';
import { RxCaretSort } from 'react-icons/rx';
import { Button, Dropdown } from 'react-bootstrap';
import appStore from '../../store/AppStore';
import axios from 'axios';
import { UserEdit } from 'iconsax-react';
import { ticketStore } from '../../store/AdminChatStore';
import Menu from './menu/Menu';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit/modal';
import { NotificationManager } from 'react-notifications';
import Avatar from '../avatar/Avatar';
import { action, makeObservable, observable } from 'mobx';
import ChangePasswordIcon from '../icon/ChangePasswordIcon';
import { USER_TYPE } from '@qbot-chat/qbot-uikit/chat';
import { FaCircle } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import Select from 'react-select';

export const AGENT_STATUS = {
  ACTIVE: {
    label: '온라인',
    icon: <FaCircle size="12" color="#28A745" />,
    color: '#28A745',
  },
  OFFLINE: {
    label: '오프라인',
    icon: <FaCircle size="12" color="#DC3545" />,
    color: '#DC3545',
  },
  AWAY: {
    label: '자리비움',
    icon: <FaCircle size="12" color="#FFC107" />,
    color: '#FFC107',
  },
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className={Styles.Toggle}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

const CustomToggleProfile = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className={Styles.ToggleProFile}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

@observer
class StatusBar extends React.Component {
  @observable
  collapsed = false;

  @observable
  isFetching = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      companyList: [],
    };
  }
  @action.bound
  handleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  componentDidMount() {
    this.isFetching = true;
    const companyList = [];
    axios
      .post('/company/list', {})
      .then((res) => {
        res?.data?.items?.map((data) => {
          companyList.push({
            value: data.id,
            label: data.companyName,
          });
        });
        this.setState({
          companyList: [...companyList],
        });
      })
      .catch((e) => console.log('StatusBar/companySearch(): ', e))
      .finally(() => (this.isFetching = false));
  }

  updateCompanyId({ value }) {
    appStore.fetchSessionInfo(value);
  }

  render() {
    return (
      <div className={Styles.Bar}>
        {[USER_TYPE.SUPER].includes(appStore?.type) && (
          <Select
            className="w-25"
            options={this.state.companyList}
            onChange={(e) => this.updateCompanyId(e)}
          />
        )}

        <div className={`d-flex align-items-center`}></div>
        <div className={`d-flex align-items-center gap-3`}>
          <div className={Styles.Status}>
            {[USER_TYPE.AGENT_LEADER, USER_TYPE.AGENT].includes(
              appStore?.type,
            ) && (
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  style={{ display: 'flex', alignItems: 'center' }}
                  id="dropdown-custom-components"
                >
                  <div
                    className={`${Styles.AgentStatus} d-flex align-items - center`}
                    style={{
                      color: AGENT_STATUS[appStore.status]?.color || '#000',
                    }}
                  >
                    {AGENT_STATUS[appStore.status]?.icon}&nbsp;&nbsp;
                    {AGENT_STATUS[appStore.status]?.label}&nbsp;&nbsp;
                    <RxCaretSort size={20} style={{ fontWeight: 'bold' }} />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ padding: 0, minWidth: '5rem' }}>
                  {Object.keys(AGENT_STATUS).map((status) => (
                    <Dropdown.Item
                      key={status}
                      style={{ padding: '0.5rem 1rem' }}
                      onClick={() => {
                        axios
                          .post('/agent/status/update', {
                            id: appStore.id,
                            status,
                          })
                          .then(() => {
                            appStore.status = status;
                            if (status === 'ACTIVE') {
                              ticketStore.initAssignment();
                            } else if (status === 'OFFLINE') {
                              ticketStore.allTransfer();
                            }
                          });
                      }}
                    >
                      <div className={'d-flex align-items-center'}>
                        {AGENT_STATUS[status].icon}&nbsp;&nbsp;
                        <div
                          style={{
                            lineHeight: 1,
                            color: AGENT_STATUS[status]?.color || '#000',
                          }}
                        >
                          {AGENT_STATUS[status]?.label}
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <Dropdown>
            <Dropdown.Toggle
              as={CustomToggleProfile}
              style={{
                display: 'flex',
                alignItems: 'center',
                background: 'transparent',
              }}
              id="dropdown-custom-components"
            >
              <div className={Styles.Profile}>
                <Avatar
                  type={'agent'}
                  src={appStore.avatar}
                  seed={appStore.id}
                  width={'1.5rem'}
                />
                {appStore.user?.type == USER_TYPE.AGENT_LEADER ? (
                  <FaStar color={'var(--bs-warning)'} />
                ) : (
                  ''
                )}
                <span className={``}>{appStore.name}</span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ padding: 0 }}>
              <div className={`${Styles.AgentInfoWrap} d-flex gap-3`}>
                <div>
                  <Avatar
                    type={'agent'}
                    src={appStore.avatar}
                    seed={appStore.id}
                    width={'4.5rem'}
                  />
                </div>
                <div className={`${Styles.UserInfo}`}>
                  <div className={Styles.UserName}>
                    {appStore.user?.type == USER_TYPE.AGENT_LEADER ? (
                      <FaStar color={'var(--bs-warning)'} />
                    ) : (
                      ''
                    )}{' '}
                    <span className={Styles.NameWrap}>{appStore.name}</span>
                  </div>
                  <div className={Styles.loginId}>{appStore.loginId}</div>
                  {appStore.team && (
                    <div className={Styles.TeamName}>{appStore.team.name}</div>
                  )}
                </div>
              </div>
              <Dropdown.Item style={{ padding: 0 }}>
                <Menu
                  className={Styles.AgentMenu}
                  collapsed={this.collapsed}
                  icon={<UserEdit />}
                  label={'개인정보 변경'}
                  to={`/main/profile`}
                />
              </Dropdown.Item>
              <Dropdown.Item style={{ padding: 0 }}>
                <Menu
                  className={Styles.AgentMenu}
                  collapsed={this.collapsed}
                  icon={<ChangePasswordIcon className={'MenuTitle'} />}
                  label={'비밀번호 변경'}
                  to={`/main/password`}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {appStore.team && (
            <div className={Styles.TeamInfo}>
              <div className={Styles.Name}>{appStore.team?.name}</div>
            </div>
          )}
          <div className={`me-2`}>
            <Button
              variant={`outline-danger`}
              onClick={() => {
                confirmDialogStore.openRemove(
                  '로그아웃',
                  '로그아웃 하시겠습니까 ?',
                  () => {
                    appStore.fetchLogout();
                    NotificationManager.success('로그아웃 되었습니다.');
                  },
                  null,
                  '로그아웃',
                );
              }}
              size={'sm'}
            >
              로그아웃
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default StatusBar;
