import React, { Component } from 'react';
import Styles from './Menu.module.scss';
import PropTypes from 'prop-types';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { navigate } from '../../../helper/HistoryHelper';
import { observer } from 'mobx-react';
import appStore from '../../../store/AppStore';

@observer
class Menu extends Component {
  render() {
    const {
      subMenu = false,
      collapsed,
      icon,
      label,
      to,
      className,
      auth,
      ...rest
    } = this.props;
    if (!!auth && !appStore.isAuthorization(auth)) return;

    const isActive =
      to === '/'
        ? appStore.currentPath === '/'
        : appStore.currentPath.startsWith(to);

    return (
      <div
        className={`${className ? className : ''} ${Styles.Menu} ${isActive ? Styles.Active : ''} ${collapsed ? Styles.Collapsed : ''}`}
        {...rest}
        onClick={() => {
          appStore.currentPath = to;
          navigate(to);
        }}
      >
        <div>{icon}</div>
        <div className={Styles.MenuTitle}>{label}</div>
        <div className={subMenu ? 'd-felx' : 'd-none'}>
          <MdKeyboardArrowRight size={'1rem'} />
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
};

export default Menu;
