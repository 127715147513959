import React, { Component } from 'react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { observer } from 'mobx-react';
import TitlePanel from '../../components/layout/TitlePanel';
import { Col, FormGroup } from 'react-bootstrap';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import { navigate } from '../../helper/HistoryHelper';
import Panel from '../../components/layout/Panel';
import Avatar from '../../components/avatar/Avatar';
import FrameWrap from '../../components/layout/FrameWrap';

const adminDataGridStore = new DataGridStore({
  fetchUrl: `/admin/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`createdAt`, Sort.SORT_DIRECTION.DESC),
});

@observer
class AdminManageListView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    adminDataGridStore.refresh();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pathname &&
      prevProps.pathname.startsWith('/main/admin/detail') &&
      this.props.pathname === '/main/admin/list'
    ) {
      adminDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    adminDataGridStore.searchCondition.clear();
  }

  search = () => {
    adminDataGridStore.fetch();
  };

  render() {
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex my-3`}
            >
              <TitlePanelLabel label={'관리자'} />
              {/* {
                                ACCESS_RIGHTS[USER_TYPE.SUPER].includes(appStore.type) &&
                                <div>
                                    <Button variant={"outline-primary"}
                                            onClick={()=>{
                                                navigate(`/main/admin/new`)
                                            }}
                                    >
                                        + 관리자 추가
                                    </Button>
                                </div>
                            } */}
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel className={'flex-default mt-5'}>
          <DataGrid
            store={adminDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`index`}
                key={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,

              <Header
                id={`loginId`}
                key={`loginId`}
                name={`로그인아이디`}
                width={{ min: 100, current: 125, max: 275 }}
              />,
              <Header
                id={`name`}
                key={`name`}
                name={`이름`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(name, agent) => {
                  return (
                    <div className={'w-100 d-flex flex-row gap-2'}>
                      <Avatar
                        type={'agent'}
                        seed={agent.id}
                        width={'1.5rem'}
                        src={agent?.avatar?.fullUrl}
                      />
                      <span>{name}</span>
                    </div>
                  );
                }}
              />,
              <Header
                id="createdAt"
                key="createdAt"
                sortable={true}
                name={`생성일`}
                className={`justify-content-end`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,

              <Header
                id={`updater`}
                key={`updater`}
                name={`수정자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(updater) => {
                  return updater?.id || '' ? (
                    <div className={'w-100 d-flex flex-row gap-2'}>
                      <Avatar
                        type={'agent'}
                        seed={updater?.id}
                        width={'1.5rem'}
                        src={updater?.avatar?.fullUrl}
                      />
                      <span>{updater?.name}</span>
                    </div>
                  ) : (
                    '-'
                  );
                }}
              />,
            ]}
            onRowClick={(rowData) => {
              navigate(`/main/admin/detail/${rowData.id}`);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export { AdminManageListView as default, adminDataGridStore };
