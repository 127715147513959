import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Required extends Component {
    render() {
        const {width, size, color} = this.props;
        return (
            <span style={{ color: color, marginRight:width, fontSize: size}}>*</span>
        );
    }
}

Required.propTypes = {
    width: PropTypes.number,
    size: PropTypes.number,
    color: PropTypes.string,
};

Required.defaultProps = {
    width: 0,
    size: 12,
    color: '#FF0000'
};

export default Required;