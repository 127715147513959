import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {observer} from "mobx-react";
import Frame from "../../components/layout/Frame";
import RegstrationImageModalView from "../../components/dialog/UploadDialog";
import AdminManageListView from "./AdminManageListView";
import AdminManageDetailView from "./AdminManageDetailView";

@observer
class AdminManageFrame extends Component {

    render() {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/admin/list' ? 'd-block' : 'd-none'} h-100`}>
                    <AdminManageListView pathname={location.pathname}/>
                </div>
                <Switch>
                    <Route path="/main/admin/new" component={AdminManageDetailView}/>
                    <Route path="/main/admin/detail/:id" component={AdminManageDetailView}/>
                    <Route path="/main/admin/modal" component={RegstrationImageModalView}/>
                    <Redirect exact from="/main/admin" to="/main/admin/list"/>
                </Switch>
            </Frame>
        );
    }
}

export default AdminManageFrame;