import * as React from 'react';

const SVGComponent = ({
  size = 24,

  color = '',
  ...props
}) => {
  return (
    <svg
      id="ChangePasswordIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
        width: size,
        height: 'auto',
        fill: color,
      }}
      xmlSpace="preserve"
      {...props}
    >
      {/*--여기까지는 기본값--*/}

      <path d="M-47.2,26h-11.1c-4,0-5.2-1.2-5.2-5.2v-6.4c0-4,1.2-5.2,5.2-5.2h11.1c4,0,5.2,1.2,5.2,5.2v6.3 C-42,24.7-43.2,26-47.2,26z M-58.3,10.7c-3.1,0-3.7,0.5-3.7,3.7v6.3c0,3.1,0.5,3.7,3.7,3.7h11.1c3.1,0,3.7-0.5,3.7-3.7v-6.3 c0-3.1-0.5-3.7-3.7-3.7H-58.3z" />
      <path d="M-52.7,30.4c-0.4,0-0.8-0.3-0.8-0.8v-4.1c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.1 C-52,30-52.3,30.4-52.7,30.4z M-43.1,21.7h-19.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h19.3c0.4,0,0.8,0.3,0.8,0.8 S-42.7,21.7-43.1,21.7z" />
      <path d="M-48.2,30.7h-9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h9c0.4,0,0.8,0.3,0.8,0.8S-47.8,30.7-48.2,30.7z" />
      <g>
        <path d="M-54.4,19.4h-3c-0.3,0-0.6-0.2-0.6-0.4v-3.1c0-0.6,0.7-1.1,1.5-1.1h2.1c0.3,0,0.6,0.2,0.6,0.4v3.7 C-53.8,19.2-54.1,19.4-54.4,19.4z M-56.8,18.2h1.8v-2.3h-1.5c-0.2,0-0.3,0.1-0.3,0.3l0,0V18.2z" />
        <path d="M-51.4,19.4h-3c-0.3,0-0.6-0.2-0.6-0.4v-5.8c0-0.6,0.7-1.1,1.5-1.1h1.2c0.8,0,1.5,0.5,1.5,1v5.7 C-50.8,19.2-51.1,19.4-51.4,19.4z M-53.8,18.2h1.8v-4.6c0-0.1-0.1-0.2-0.3-0.2l0,0h-1.2c-0.2,0-0.3,0.1-0.3,0.2l0,0v4.9V18.2z" />
        <path d="M-48.4,19.4h-3c-0.3,0-0.6-0.2-0.6-0.4v-2.7c0-0.2,0.3-0.4,0.6-0.4h2.1c0.8,0,1.5,0.5,1.5,1.1v2 C-47.8,19.2-48,19.4-48.4,19.4z M-50.8,18.1h1.8v-1c0-0.1-0.1-0.2-0.3-0.2h-1.5V18.1z" />
      </g>
      <g>
        <g>
          <path d="M-16.4,32c-0.7,0-1.3-0.3-1.8-1l-1.5-2c0,0-0.1-0.1-0.2-0.1h-0.5c-4.2,0-6.8-1.1-6.8-6.8v-5 c0-4.4,2.3-6.7,6.8-6.7h8c4.4,0,6.8,2.3,6.8,6.7v5c0,4.4-2.3,6.8-6.8,6.8h-0.5c-0.1,0-0.2,0-0.2,0.1l-1.5,2 C-15.1,31.7-15.7,32-16.4,32z M-20.4,12c-3.6,0-5.2,1.7-5.2,5.2v5c0,4.5,1.5,5.2,5.2,5.2h0.5c0.5,0,1.1,0.3,1.4,0.7l1.5,2 c0.3,0.5,0.9,0.5,1.2,0l1.5-2c0.3-0.4,0.9-0.7,1.4-0.7h0.5c3.6,0,5.3-1.7,5.3-5.2v-5c0-3.6-1.7-5.2-5.3-5.2H-20.4z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M-20.8,22.6h-0.7c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9-0.3-1.9c0-0.4,0.1-1.1,0-1.4 c-0.2-0.1-0.4-0.1-0.4-0.1c-0.4-0.1-0.7-0.4-0.7-0.8s0.3-0.7,0.7-0.7c0,0,0.1,0,0.4-0.1c0-0.3,0-1,0-1.4c-0.1-1-0.1-1.5,0.3-1.9 c0.1-0.1,0.4-0.3,0.6-0.3h0.7c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8h0c0,0.2,0,0.4,0,0.6c0.1,1,0.1,1.8-0.2,2.3 c0.3,0.5,0.2,1.3,0.2,2.3c0,0.2,0,0.4,0,0.6h0c0.4,0,0.8,0.3,0.8,0.8S-20.4,22.6-20.8,22.6z" />
        </g>
        <g>
          <path d="M-11.3,22.6H-12c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0c0-0.2,0-0.4,0-0.6c-0.1-1-0.1-1.8,0.2-2.3 c-0.3-0.5-0.2-1.3-0.2-2.3c0-0.2,0-0.4,0-0.6h0c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.7c0.2,0,0.4,0.1,0.6,0.3 c0.3,0.3,0.3,0.9,0.3,1.9c0,0.4-0.1,1.1,0,1.4c0.2,0.1,0.4,0.1,0.4,0.1c0.4,0.1,0.7,0.4,0.7,0.8c0,0.4-0.3,0.7-0.7,0.7l0,0 c0,0-0.1,0-0.4,0.1c0,0.3,0,1,0,1.4c0.1,1,0.1,1.5-0.3,1.9C-10.9,22.5-11.1,22.6-11.3,22.6z" />
        </g>
      </g>
      <g>
        <path d="M-17.4,20.8c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.6-0.6-0.4-1l2-4.7c0.2-0.4,0.6-0.6,1-0.4c0.4,0.2,0.6,0.6,0.4,1 l-2,4.7C-16.8,20.6-17.1,20.8-17.4,20.8z" />
      </g>
      <g>
        <g>
          <path d="M-24.2,7.3h-4.5c-2.2,0-3-0.8-3-3V2.9c0-1.7,0.5-2.6,1.8-2.9v-0.3c0-2.3,1.2-3.4,3.4-3.4 c2.3,0,3.4,1.2,3.4,3.4V0c1.3,0.3,1.8,1.1,1.8,2.9v1.4C-21.2,6.5-22,7.3-24.2,7.3z M-28.7,1.4c-1.4,0-1.5,0.1-1.5,1.5v1.4 c0,1.4,0.1,1.5,1.5,1.5h4.5c1.4,0,1.5-0.1,1.5-1.5V2.9c0-1.4-0.1-1.5-1.5-1.5H-28.7z M-28.4-0.1h3.9v-0.1c0-1.3-0.3-1.9-1.9-1.9 c-1.6,0-1.9,0.7-1.9,1.9V-0.1z" />
        </g>
        <g>
          <path d="M-26.4,4.5c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S-26,4.5-26.4,4.5z" />
        </g>
      </g>
      <g>
        <path d="M8.4,23.6c-4.3,0-7.8-3.5-7.8-7.8c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8c0,3,2.1,5.4,4.8,6.1l-0.3-0.5 c-0.2-0.4-0.1-0.8,0.3-1c0.4-0.2,0.8-0.1,1,0.3l1,1.8c0.1,0.2,0.1,0.5,0,0.8C8.9,23.4,8.6,23.6,8.4,23.6z M22.6,8.9 c-0.4,0-0.8-0.3-0.8-0.8c0-3-2.1-5.4-4.8-6.1l0.3,0.5c0.2,0.4,0.1,0.8-0.3,1c-0.4,0.2-0.8,0.1-1-0.3l-1-1.8c-0.1-0.2-0.1-0.5,0-0.8 c0.1-0.2,0.4-0.4,0.7-0.4c4.3,0,7.8,3.5,7.8,7.8C23.4,8.6,23.1,8.9,22.6,8.9z" />
      </g>
      <g>
        <circle cx={12.7} cy={14.1} r={1.4} />
        <path d="M16.9,9.2V8.7c0-1.1,0-4.3-4.3-4.3c-4.3,0-4.3,3.2-4.3,4.3v0.5c-1.7,0.3-2.3,1.3-2.3,3.6v2.6 C6.1,18.1,7,19,9.8,19h5.8c2.7,0,3.7-0.9,3.7-3.7v-2.6C19.3,10.5,18.6,9.5,16.9,9.2z M12.7,5.9c2.3,0,2.8,1,2.8,2.8v0.4H9.9V8.7 C9.9,6.9,10.4,5.9,12.7,5.9z M17.8,15.4c0,1.9-0.2,2.2-2.2,2.2H9.8c-1.9,0-2.2-0.2-2.2-2.2v-2.6c0-1.9,0.2-2.2,2.2-2.2h5.8 c1.9,0,2.2,0.2,2.2,2.2V15.4z" />
      </g>
    </svg>
  );
};
export default SVGComponent;
