import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonGroup,
  InputGroup,
  Nav,
  ToggleButton,
  Button,
} from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import { computed, makeObservable, observable, reaction } from 'mobx';
import SummaryReport from './SummaryReport';
import AgentReport, { agentReportDataGridStore } from './AgentReport';
import TeamReport, { teamReportDataGridStore } from './TeamReport';
import BotReport, { botReportDataGridStore } from './BotReport';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import Select, { components } from 'react-select';
import { BsDownload, BsGraphUp, BsTable } from 'react-icons/bs';
import axios from 'axios';
import FileSaver from 'file-saver';
import AgentSelect from '../../components/select/AgentSelect';
import TeamSelect from '../../components/select/TeamSelect';
import FrameWrap from '../../components/layout/FrameWrap';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';

const { RangePicker } = DatePicker;

const cond = [
  {
    label: '어제',
    value: 'yesterday',
  },
  {
    label: '지난 7일',
    value: 'week',
  },
  {
    label: '지난 14일',
    value: '2week',
  },
  {
    label: '지난 30일',
    value: 'month',
  },
  {
    label: '지난 90일',
    value: '3month',
  },
  {
    label: '직접 선택',
    value: 'select',
  },
];
@observer
class ReportView extends Component {
  @observable
  tabId = 'summary';

  @observable
  startDate = dayjs().subtract(14, 'day');

  @observable
  endDate = dayjs().subtract(1, 'day');

  @observable
  viewMode = 'table';

  @observable
  selectedAgent = null;

  @observable
  selectedBot = null;

  @observable
  selectedTeam = null;

  selectRef;
  @computed
  get searchDates() {
    return [this.startDate, this.endDate];
  }

  @observable
  selectedCond = cond[2];

  @computed
  get downloadInfo() {
    return {
      agent: {
        url: '/report/agent/download',
        searchCondition: agentReportDataGridStore.searchConditionWithPage,
      },
      team: {
        url: '/report/team/download',
        searchCondition: teamReportDataGridStore.searchConditionWithPage,
      },
      bot: {
        url: '/report/bot/download',
        searchCondition: botReportDataGridStore.searchConditionWithPage,
      },
    }[this.tabId];
  }

  constructor(props) {
    super(props);
    makeObservable(this);
    reaction(
      () => this.tabId,
      () => {
        this.selectedBot = null;
        this.selectedAgent = null;
        this.selectedTeam = null;
      },
    );
  }

  ValueContainer = ({ children, ...props }) => {
    if (props.getValue()[0].value === 'select') {
      return (
        <RangePicker
          className={'me-1 ms-1'}
          allowClear={false}
          value={this.searchDates}
          disabledDate={(currentDate) => {
            return (
              currentDate.isAfter(this.startDate.add(90, 'day')) ||
              currentDate.isAfter(dayjs(), 'day') ||
              currentDate.isSame(dayjs(), 'day')
            );
          }}
          onChange={(dates) => {
            this.startDate = dates ? dates[0] : null;
            this.endDate = dates ? dates[1] : null;
          }}
        />
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  tabContent = () => {
    return {
      summary: (
        <SummaryReport startDate={this.startDate} endDate={this.endDate} />
      ),
      agent: (
        <AgentReport
          startDate={this.startDate}
          endDate={this.endDate}
          viewMode={this.viewMode}
          selectedAgent={this.selectedAgent}
        />
      ),
      team: (
        <TeamReport
          startDate={this.startDate}
          endDate={this.endDate}
          viewMode={this.viewMode}
          selectedTeam={this.selectedTeam}
        />
      ),
      bot: (
        <BotReport
          startDate={this.startDate}
          endDate={this.endDate}
          viewMode={this.viewMode}
          selectedBot={this.selectedBot}
        />
      ),
    }[this.tabId];
  };

  buttonView = () => {
    return ['agent', 'team', 'bot'].includes(this.tabId) ? (
      <ButtonGroup>
        <ToggleButton
          className="mb-2 d-flex h-100"
          id="toggle-1"
          type="radio"
          variant="outline-secondary"
          checked={this.viewMode === 'table'}
          value="table"
          onChange={(e) => (this.viewMode = e.currentTarget.value)}
        >
          <BsTable className={'align-self-center'} />
        </ToggleButton>
        <ToggleButton
          className="mb-2 d-flex h-100"
          id="toggle-2"
          type="radio"
          variant="outline-secondary"
          checked={this.viewMode === 'graph'}
          value="graph"
          onChange={(e) => (this.viewMode = e.currentTarget.value)}
        >
          <BsGraphUp className={'align-self-center'} />
        </ToggleButton>
      </ButtonGroup>
    ) : (
      <></>
    );
  };

  // downloadButton = () => {
  //   return (
  //     <div>
  //       <Button
  //         disabled={this.viewMode === 'graph'}
  //         variant="outline-secondary"
  //         className={'ms-2'}
  //         style={{ width: '8rem' }}
  //         onClick={() => this.excelFile()}
  //       >
  //         내려받기 <BsDownload />
  //       </Button>
  //     </div>
  //   );
  // };

  // excelFile = () => {
  //   axios
  //     .post(this.downloadInfo.url, this.downloadInfo.searchCondition, {
  //       headers: {
  //         Accept: '*/*',
  //       },
  //       responseType: 'arraybuffer',
  //       maxContentLength: Infinity,
  //       maxBodyLength: Infinity,
  //     })
  //     .then((res) => {
  //       let filename = JSON.stringify(res.headers)
  //         .split('filename=')[1]
  //         .split('",')[0];
  //       let blob = new Blob([res.data], {
  //         type: 'application/octet-stream',
  //       });

  //       FileSaver.saveAs(blob, decodeURI(filename));
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //       if (e.response.data && e.response.data.message) {
  //         alert(e.response.data.message);
  //       }
  //     });
  // };

  render() {
    return (
      <FrameWrap className={'w-100 overflow-y-auto'}>
        <TitlePanel>
          <TitlePanelLabel label={'리포트'} />
        </TitlePanel>
        <Panel>
          <Nav
            variant="underline"
            defaultActiveKey={'summary'}
            activeKey={this.tabId}
          >
            <Nav.Item>
              <Nav.Link
                eventKey="summary"
                onClick={() => (this.tabId = 'summary')}
              >
                요약
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="agent" onClick={() => (this.tabId = 'agent')}>
                상담원
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="team" onClick={() => (this.tabId = 'team')}>
                팀
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bot" onClick={() => (this.tabId = 'bot')}>
                봇
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className={`mt-3 d-flex justify-content-between`}>
            <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                ref={this.selectRef}
                styles={{
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    width: '100px',
                  }),
                }}
                blurInputOnSelect={true}
                components={{ ValueContainer: this.ValueContainer }}
                options={cond}
                defaultValue={this.selectedCond}
                onChange={(e) => {
                  this.selectedCond = e;
                  switch (e.value) {
                    case 'yesterday': {
                      this.startDate = dayjs().subtract(1, 'day');
                      this.endDate = dayjs().subtract(1, 'day');
                      break;
                    }
                    case 'week': {
                      this.startDate = dayjs().subtract(7, 'day');
                      this.endDate = dayjs().subtract(1, 'day');
                      break;
                    }
                    case '2week': {
                      this.startDate = dayjs().subtract(14, 'day');
                      this.endDate = dayjs().subtract(1, 'day');
                      break;
                    }
                    case 'month': {
                      this.startDate = dayjs().subtract(30, 'day');
                      this.endDate = dayjs().subtract(1, 'day');
                      break;
                    }
                    case '3month': {
                      this.startDate = dayjs().subtract(90, 'day');
                      this.endDate = dayjs().subtract(1, 'day');
                      break;
                    }
                  }
                }}
              />
              {this.tabId === 'agent' && (
                <AgentSelect
                  styles={{
                    menu: (base) => ({
                      ...base,
                      width: '15rem',
                      zIndex: 12,
                    }),
                  }}
                  className={'ms-2'}
                  placeholder="모든 상담원"
                  types={['AGENT', 'AGENT_LEADER', 'AGENT_BOT']}
                  onChange={(e) => {
                    if (e && e.object) {
                      this.selectedAgent = e.object;
                    } else {
                      this.selectedAgent = null;
                    }
                  }}
                />
              )}
              {this.tabId === 'team' && (
                <TeamSelect
                  styles={{
                    menu: (base) => ({
                      ...base,
                      width: '15rem',
                      zIndex: 12,
                    }),
                  }}
                  className={'ms-2'}
                  placeholder="모든 팀"
                  onChange={(e) => {
                    if (e && e.object) {
                      this.selectedTeam = e.object;
                    } else {
                      this.selectedTeam = null;
                    }
                  }}
                />
              )}
              {this.tabId === 'bot' && (
                <AgentSelect
                  styles={{
                    menu: (base) => ({
                      ...base,
                      width: '15rem',
                      zIndex: 12,
                    }),
                  }}
                  types={['AGENT_BOT']}
                  className={'ms-2'}
                  placeholder="모든 봇"
                  onChange={(e) => {
                    if (e && e.object) {
                      this.selectedBot = e.object;
                    } else {
                      this.selectedBot = null;
                    }
                  }}
                />
              )}
            </InputGroup>

            <div className={'d-flex flex-row'}>
              {this.buttonView()}
              {/* {this.tabId !== 'summary' && this.downloadButton()} */}
            </div>
          </div>
        </Panel>
        <div className={'w-100 h-100'}>{this.tabContent()}</div>
      </FrameWrap>
    );
  }
}

export default ReportView;
