import React, { Component } from 'react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { observer } from 'mobx-react';
import Panel from '../../components/layout/Panel';
import { Badge, Button, Col, FormGroup } from 'react-bootstrap';
import { navigate } from '../../helper/HistoryHelper';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import FrameWrap from '../../components/layout/FrameWrap';
import { FaCircle } from 'react-icons/fa';

const ticketPropertyDataGridStore = new DataGridStore({
  fetchUrl: `/ticket/property/setting/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`updatedAt`, Sort.SORT_DIRECTION.DESC),
  noHeader: true,
});

const TYPE = {
  STRING: '문자',
  NUMBER: '숫자',
  DATE: '날짜',
  BOOLEAN: '예/아니오',
  ENUM: '열거',
};

@observer
class TicketPropertyListView extends Component {
  componentDidMount() {
    ticketPropertyDataGridStore.refresh();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pathname &&
      prevProps.pathname.startsWith('/main/setting/manage/property/detail') &&
      this.props.pathname === '/main/setting/manage/property/list'
    ) {
      ticketPropertyDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    ticketPropertyDataGridStore.searchCondition.clear();
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      ticketPropertyDataGridStore.page = 1;
      ticketPropertyDataGridStore.fetch();
    }
  };

  search = () => {
    ticketPropertyDataGridStore.fetch();
  };

  render() {
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'티켓 설정 - 티켓 필드'} />
              <Button
                variant={'outline-primary'}
                onClick={() => {
                  navigate(`/main/setting/manage/property/new`);
                }}
              >
                + 티켓필드 추가
              </Button>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel className={'flex-grow-1'}>
          <DataGrid
            store={ticketPropertyDataGridStore}
            keyColumn={`fieldKey`}
            columns={[
              <Header
                id={`index`}
                key={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,
              <Header
                id={`name`}
                key={`name`}
                name={`라벨`}
                width={{ min: 36, current: 160, max: 200 }}
              />,
              <Header
                id={`fieldKey`}
                key={`fieldKey`}
                name={`필드키`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(fieldKey) => (
                  <Badge pill bg={'secondary'} className="IdBadge">
                    {fieldKey}
                  </Badge>
                )}
              />,
              <Header
                id={`type`}
                key={`type`}
                name={`유형`}
                width={{ min: 90, current: 100, max: 110 }}
                printFunction={(type) => TYPE[type]}
              />,
              <Header
                id={`isRequired`}
                key={`isRequired`}
                name={`필수`}
                printFunction={(isRequired) => (isRequired ? '필수' : '선택')}
              />,
              <Header
                id={`isActive`}
                key={`isActive`}
                name={`사용`}
                printFunction={(isActive) => (
                  <div className={'d-flex flex-row gap-1 align-items-center'}>
                    <FaCircle
                      className={'d-flex flex-row gap-1 align-items-center'}
                      style={{
                        color: isActive
                          ? 'var(--bs-success)'
                          : 'var(--bs-danger)',
                        fontSize: '0.5rem',
                      }}
                    />
                    {isActive ? '사용' : '미사용'}
                  </div>
                )}
              />,
              <Header
                id="updatedAt"
                key="updatedAt"
                sortable={true}
                name={`마지막 업테이트`}
                className={`justify-content-end`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
            ]}
            onRowClick={(rowData) => {
              navigate(
                `/main/setting/manage/property/detail/${rowData.fieldKey}`,
              );
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export { TicketPropertyListView as default, ticketPropertyDataGridStore };
