import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { FaTrash, FaFileExcel } from 'react-icons/fa';
import { navigate } from '../../helper/HistoryHelper';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import { Select, Button as Refresh } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Panel from '../../components/layout/Panel';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import FrameWrap from '../../components/layout/FrameWrap';
import { makeObservable, observable } from 'mobx';
import { ACCESS_RIGHTS } from '../../const/AppConst';
import { USER_TYPE } from '@qbot-chat/qbot-uikit';
import appStore from '../../store/AppStore';
import { DatePicker, Table, Modal } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import { Warning2 } from 'iconsax-react';
import { NotificationManager } from 'react-notifications';
import * as XLSX from 'xlsx';
import { PDS_CONTEXT_PATH } from '../../const/AppConst';
import Styles from './CallListView.module.scss';
import _ from 'lodash';

// import { ColumnPanelStore } from "@qbot-chat/qbot-uikit/stories/component/ui/dataGrid/ColumnPanelCheckBox";
import { CustomButton } from '@qbot-chat/qbot-uikit/component';

const axiosInstancePDS = axios.create({
  baseURL: `${PDS_CONTEXT_PATH}`,
  withCredentials: true,
});
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const excelGuide = [
  {
    시나리오ID: 'TEST0001',
    전화번호: `="01012345678"`,
  },
];

const callStatusOptions = [
  { label: '전화연결 대기', value: '0' },
  { label: '전화연결중', value: '1' },
  { label: '전화연결 성공', value: '2' },
  { label: '전화연결 정상종료', value: '3' },
  { label: '전화연결 실패', value: '4' },
  { label: '없는 번호', value: '5' },
];

const callDataGridStore = new DataGridStore({
  fetchUrl: `/call/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`createdAt`, Sort.SORT_DIRECTION.DESC),
});

@observer
class CallListView extends Component {
  @observable
  callStatus = null;

  @observable
  keyword = null;

  @observable
  startDate = null;

  @observable
  endDate = null;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      isModal: false,
      isTable: false,
      excelSource: [],
      excelColumn: [
        {
          title: '시나리오ID',
          dataIndex: 'scenarioId',
          key: 'scenarioId',
        },
        {
          title: '전화번호',
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
        },
      ],
    };
  }

  componentDidMount() {
    callDataGridStore.refresh();
  }

  componentWillUnmount() {
    callDataGridStore.searchCondition.clear();
  }

  activeHandler = (value) => {
    this.callStatus = value;
    this.keyword = value;
    this.startDate = value;
    this.endDate = value;
    this.search();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      callDataGridStore.page = 1;
      this.search();
    }
  };

  excelUpload(e) {
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const xlsx = XLSX.read(arrayBuffer, {
          type: 'buffer',
          cellText: false,
          cellDates: true,
        });
        const sheetName = xlsx.SheetNames[0];
        const rawData = xlsx.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(rawData);
        const newSource = [];
        excelData?.map((d, i) => {
          let newObj = { key: `${i + 1}` };
          newObj['scenarioId'] = d['시나리오ID'];
          newObj['phoneNumber'] = `0${d['전화번호']}`;
          return newSource.push(newObj);
        });
        return this.setState({
          isTable: true,
          excelSource: newSource,
        });
      };
    }
  }

  saveScenario() {
    const reqData = [];
    this.state.excelSource.map((data) => {
      const scenarioId = data.scenarioId;
      reqData.push({
        scenarioId: scenarioId,
        phoneNumber: String(data.phoneNumber),
      });
    });
    axiosInstancePDS
      .post('/call/addNumber', reqData)
      .then(() => {
        NotificationManager.success('저장을 완료했습니다.');
        this.setState({
          isTable: false,
          isModal: false,
          excelSource: [],
        });
        callDataGridStore.fetch();
      })
      .catch((e) => {
        console.log(e);
        NotificationManager.error('데이터 저장에 실패하였습니다.');
      });
  }

  excelDownload(data) {
    const excelFileName = 'DOWNLOAD.csv';
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, excelFileName);
  }

  search = () => {
    let obj = Object.fromEntries(callDataGridStore.searchCondition);
    callDataGridStore.searchCondition.set('keyword', this.keyword);
    callDataGridStore.searchCondition.set('callStatus', this.callStatus);
    callDataGridStore.searchCondition.set(
      'startDate',
      this.startDate && dayjs(this.startDate).format(dateFormat),
    );
    callDataGridStore.searchCondition.set(
      'endDate',
      this.endDate && dayjs(this.endDate).format(dateFormat),
    );

    if (
      !_.isEqual(obj, Object.fromEntries(callDataGridStore.searchCondition))
    ) {
      callDataGridStore.refresh();
    } else {
      callDataGridStore.fetch();
    }
  };

  setDateSorting(dates) {
    if (dates[0]) this.startDate = dates[0];
    if (dates[1]) this.endDate = dates[1];
    this.search();
  }

  delete() {
    confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
      const selectedData = [];
      callDataGridStore.checkedRowEntries.map(([, data]) => {
        selectedData.push({
          basDt: data['basDt'],
          scenarioId: data['scenarioId'],
          phoneNumber: data['phoneNumber'],
        });
      });
      axiosInstancePDS
        .put(`/call/delNumber`, selectedData)
        .then(() => {
          NotificationManager.success('데이터가 삭제되었습니다.');
          callDataGridStore.fetch();
        })
        .catch((e) => {
          console.error(e);
          NotificationManager.error('삭제 실패하였습니다.');
        });
    });
  }

  render() {
    const { excelColumn, excelSource, isTable } = this.state;
    console.log(excelSource);
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'아웃바운드 전화번호 목록'} />
              <div>
                <Button
                  variant={'outline-primary'}
                  onClick={() => {
                    navigate(`/main/call/new`);
                  }}
                >
                  + 직접등록
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Modal
          className="w-50"
          title={
            <div className="d-flex justify-content-end">
              <Button
                className={`${Styles.ModalExcelIcon} btn btn-outline-secondary btn-sm mb-2`}
                variant={'outline-primary'}
                onClick={() => this.excelDownload(excelGuide)}
              >
                <FaFileExcel />
                {`가이드 XLS`}
              </Button>
            </div>
          }
          open={this.state.isModal}
          centered
          closable={false}
          maskClosable={false}
          cancelText="닫기"
          okText="저장"
          onCancel={() =>
            this.setState({
              isTable: false,
              isModal: false,
              excelSource: [],
            })
          }
          onOk={() => this.saveScenario()}
        >
          {isTable ? (
            <div style={{ height: 670 }}>
              <Table
                dataSource={excelSource}
                columns={excelColumn}
                size="large"
              />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center py-5 bg-light"
              style={{ height: 670 }}
            >
              <input
                type="file"
                accept=".xlsx, .xls, .csv"
                ref={(ref) => (this.fileInput = ref)}
                style={{ display: 'none' }}
                onChange={(e) => {
                  this.excelUpload(e);
                }}
              />
              <Button
                className={`${Styles.ModalExcelIcon} btn btn-outline-secondary btn-sm`}
                variant={'outline-primary'}
                onClick={() => this.fileInput.click()}
              >
                <FaFileExcel />
                {`엑셀 업로드`}
              </Button>
            </div>
          )}
        </Modal>
        <Panel>
          <Panel
            className={`rounded-3`}
            style={{ border: '1px solid #E8E8E8' }}
          >
            <FormGroup>
              <Col className={`d-flex flex-column`}>
                <div className={'d-flex justify-content-between'}>
                  <Col md={6}>
                    {ACCESS_RIGHTS[USER_TYPE.SUPER].includes(appStore.type) ? (
                      <div className="d-flex align-items-center gap-3">
                        <div>
                          <RangePicker
                            placeholder={['상담시작일', '상담종료일']}
                            size="large"
                            format={dateFormat}
                            allowEmpty
                            value={[this.startDate, this.endDate]}
                            onChange={(dates) => {
                              this.setDateSorting(dates);
                            }}
                          />
                        </div>
                        <Select
                          defaultValue={'실행 상태'}
                          style={{ width: 180, height: 38, fontSize: '1rem' }}
                          size="large"
                          onChange={this.activeHandler}
                          options={callStatusOptions}
                        />
                        <Refresh
                          shape="circle"
                          icon={<ReloadOutlined />}
                          onClick={() => this.activeHandler(null)}
                        />
                      </div>
                    ) : null}
                  </Col>
                  <Col md={{ span: 4, offset: 1 }}>
                    <InputGroup>
                      <Form.Control
                        placeholder="내용 입력"
                        value={this.searchKeyword || ''}
                        onChange={(e) => {
                          this.keyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={'outline-secondary'}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className={'flex-default'}>
          <DataGrid
            store={callDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`basDt`}
                key={`basDt`}
                name={`기준일`}
                width={{ min: 100, current: 125, max: 250 }}
              />,
              <Header
                id={`scenarioId`}
                key={`scenarioId`}
                name={`시나리오ID`}
                width={{ min: 100, current: 125, max: 275 }}
                printFunction={(data) => (
                  <Badge pill bg="secondary" className="IdBadge">
                    {data}
                  </Badge>
                )}
              />,
              <Header
                id={`phoneNumber`}
                key={`phoneNumber`}
                name={`전화번호`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(phoneNumber) => {
                  return (
                    <span>
                      {phoneNumber.replaceAll('"', '').replace('=', '')}
                    </span>
                  );
                }}
              />,
              <Header
                id={`callStatusName`}
                key={`callStatusName`}
                name={`상태`}
                width={{ min: 100, current: 125, max: 275 }}
                printFunction={(callStatusName) => (
                  <span>{callStatusName}</span>
                )}
              />,
              <Header
                id={`ticketId`}
                key={`ticketId`}
                name={`티켓ID`}
                width={{ min: 50, current: 80, max: 100 }}
                printFunction={(id) => {
                  return id ? <span>{id}</span> : <span>-</span>;
                }}
              />,

              <Header
                id={`createdAt`}
                key={`createdAt`}
                name={`최초 등록 일시`}
                sortable={true}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,

              <Header
                id={`creator`}
                key={`creator`}
                name={`최초 등록자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(creator) => {
                  return creator ? <span>{creator}</span> : <span>-</span>;
                }}
              />,
              <Header
                id={`updatedAt`}
                key={`updatedAt`}
                name={`최종 수정 일시`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
              <Header
                id={`updater`}
                key={`updater`}
                name={`최종 수정자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(updater) => {
                  return updater ? <span>{updater}</span> : <span>-</span>;
                }}
              />,
            ]}
            customButton={
              <>
                {callDataGridStore.checkedRowEntries.length > 0 && (
                  <Button
                    className={`btn btn-outline-secondary btn-sm`}
                    style={{ zIndex: 1 }}
                    variant={'outline-primary'}
                    onClick={() => {
                      this.delete();
                    }}
                  >
                    <FaTrash /> 삭제
                  </Button>
                )}
                <Button
                  className={`${Styles.ExcelIcon} btn btn-outline-secondary btn-sm`}
                  variant={'outline-primary'}
                  onClick={() =>
                    this.setState({
                      isModal: true,
                    })
                  }
                >
                  <FaFileExcel />
                  {`엑셀 업로드`}
                </Button>
              </>
            }
            onRowClick={(rowData) => {
              navigate(
                `/main/call/detail?basDt=${rowData.basDt}&sid=${rowData.scenarioId}&num=${rowData.phoneNumber}`,
              );
            }}
            isCheckable={true}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export {
  CallListView as default,
  callDataGridStore as callDataGridStore,
  axiosInstancePDS as axiosInstancePDS,
};
