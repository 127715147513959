import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { FormStore, Input, Validation } from '../../components/validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { companyDataGridStore } from './CompanyListView';
import { goBack, navigateReplace } from '../../helper/HistoryHelper';
import Panel from '../../components/layout/Panel';
import { ConfirmDialog, confirmDialogStore } from '@qbot-chat/qbot-uikit';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import { makeObservable } from 'mobx';
import { Warning2 } from 'iconsax-react';
import dayjs from 'dayjs';
import { DatePicker, Select as ServiceSelect } from 'antd';
import Cookies from 'js-cookie';
import FrameWrap from '../../components/layout/FrameWrap';

const { RangePicker } = DatePicker;
const form = new FormStore();
const dateFormat = 'YYYY-MM-DD';

const ServiceOptions = [
  { label: <span>Basic</span>, value: '1' },
  { label: <span>Standard</span>, value: '2' },
  { label: <span>Enterprise</span>, value: '3' },
];

@observer
class CompanyDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      imgName: '',
    };
    makeObservable(this);
  }

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params && params.id) {
      this.fetchDetail(params.id);
    } else {
      form.clear();
    }
    if (Cookies.get('id')) {
      const adminId = Cookies.get('id');
      form.setValue('adminId', adminId);
    }
  }

  componentDidUpdate(prevProps) {
    let {
      match: { params },
    } = this.props;
    let prevParams = prevProps.match.params;
    if (params.id != prevParams.id) {
      if (params && params.id) {
        this.fetchDetail(params.id);
      } else if (!params.id) {
        form.clear();
      }
    }
  }

  fetchDetail(id) {
    axios.post(`/company/detail/${id}`, {}).then((res) => {
      form.values = res.data;
      this.setState({
        isActive: res.data?.isActive,
      });
    });
  }
  validCheck(loginId) {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }
    axios.post(`/user/check/loginId/${loginId}`).then((res) => {
      if (res.data) {
        return NotificationManager.error('이미 사용중인 로그인 아이디 입니다.');
      } else
        return NotificationManager.success('사용 가능한 로그인 아이디 입니다.');
    });
  }

  save() {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }
    form.setValue('isActive', this.state.isActive);
    if (!!this.props.match.params.id) {
      axios.post(`/company/update`, form.values).then((res) => {
        let id = res.data;
        companyDataGridStore.fetch();
        this.fetchDetail(id);
        NotificationManager.success('변경사항이 저장되었습니다.');
      });
    } else {
      axios.post(`/company/insert`, form.values).then((res) => {
        let id = res.data;
        NotificationManager.success('회사가 등록되었습니다.');
        companyDataGridStore.fetch();
        navigateReplace(`/main/company/detail/${id}`);
      });
    }
  }

  delete() {
    if (!!this.props.match.params.id) {
      confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
        axios
          .post(`/company/delete`, form.values)
          .then(() => {
            NotificationManager.success('회사가 삭제되었습니다.');
            navigateReplace(`/main/company/list`);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  }

  encodeFileToBase64(fileBlob) {
    if (fileBlob) {
      const reader = new FileReader();
      reader.readAsDataURL(fileBlob);
      reader.onload = () => {
        form.setValue(
          'documentFile',
          reader.result.split(',')[1],
          // .replace("data:image/png;base64,", "")
          // .replace("data:image/jpeg;base64,", "")
          // .replace("data:image/jpg;base64,", "")
        );
        this.setState({
          imgName: fileBlob.name,
        });
      };
    } else {
      this.setState({
        imgName: '',
      });
    }
  }

  setContractDate(dates) {
    dates[0] &&
      form.setValue('contractStartDate', dayjs(dates[0]).format('YYYY-MM-DD'));
    dates[1] &&
      form.setValue('contractEndDate', dayjs(dates[1]).format('YYYY-MM-DD'));
  }

  render() {
    let isNew = !form.valueMap.get('id');
    let contractStartDate = form.values.contractStartDate;
    let contractEndDate = form.values.contractEndDate;
    let serviceType =
      form.values.serviceType === '1'
        ? 'Basic'
        : form.values.serviceType === '2'
          ? 'Standard'
          : form.values.serviceType === '3'
            ? 'Enterprise'
            : null;
    return (
      <FrameWrap className={`d-flex flex-column flex-default overflow-y-auto`}>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              {isNew ? (
                <TitlePanelLabel label={'회사 등록하기'} />
              ) : (
                <TitlePanelLabel label={'상세정보'} />
              )}
              <div>
                {!isNew && (
                  <Button
                    variant={'outline-danger'}
                    onClick={() => this.delete()}
                  >
                    삭제
                  </Button>
                )}

                <Button
                  variant={'outline-secondary'}
                  className={'ms-4'}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                <Button
                  variant={'outline-primary'}
                  className={`ms-1`}
                  onClick={() => this.save()}
                >
                  저장
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup as={Row}>
              <Col className="pt-4">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">업체명</FormLabel>
                    <div className="w-75">
                      <Input
                        store={form}
                        label={`업체명`}
                        name={`companyName`}
                        validOnBlur
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pt-4">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">사업자등록</FormLabel>
                    <div className="w-75">
                      <Input
                        store={form}
                        label={`사업자등록`}
                        name={`registrationNumber`}
                        placeholder={`사업자 등록 번호`}
                        disabled={!isNew}
                        style={{ borderRadius: '0.375rem 0 0 0.375rem' }}
                        button={
                          isNew && (
                            <Button
                              variant={`outline-primary`}
                              onClick={() => this.fileInput.click()}
                            >
                              사업자등록증 업로드
                            </Button>
                          )
                        }
                      />
                      <span className="position-absolute">
                        {this.state.imgName}
                      </span>
                    </div>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={(ref) => (this.fileInput = ref)}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      this.encodeFileToBase64(e.target.files[0]);
                    }}
                  />
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              {/* <Col className={`mb-3`}>
                <FormLabel>상위기업</FormLabel>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Check
                    type="switch"
                    checked={this.state.isParentCompany}
                    onChange={(e) =>
                      this.setState({
                        isParentCompany: e.target.checked,
                      })
                    }
                  />
                  <Select
                    className="w-50"
                    options={[
                      { label: "퀀텀", value: "quantum" },
                      { label: "퀀텀100", value: "quantum100" },
                      { label: "퀀텀200", value: "quantum200" },
                    ]}
                  />
                </div>
              </Col> */}
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">종사업자 번호</FormLabel>
                    <div className="w-75">
                      <Input
                        store={form}
                        disabled={!isNew}
                        label={`종사업자 번호`}
                        name={`registrationNumberSub`}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">사이트 URL</FormLabel>
                    <div className="w-75">
                      <Input
                        store={form}
                        label={`사이트 URL`}
                        name={`homepageUrl`}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </FormGroup>
            <Form.Group as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">계약일</FormLabel>
                    <div className="w-75">
                      <RangePicker
                        placeholder={['계약시작일', '계약종료일']}
                        size="large"
                        format={dateFormat}
                        disabled={!isNew}
                        allowEmpty
                        value={[
                          contractStartDate &&
                            dayjs(contractStartDate, dateFormat),
                          contractEndDate && dayjs(contractEndDate, dateFormat),
                        ]}
                        onChange={(dates) => {
                          this.setContractDate(dates);
                        }}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">주소</FormLabel>
                    <div className="w-75">
                      <Input store={form} label={'주소'} name={`address`} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 mx-4">
                    <div className="w-75">
                      <Input
                        store={form}
                        label={'상세주소'}
                        name={`addressDetail`}
                        placeholder={`상세 주소입력`}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Form.Group>
            <FormGroup as={Row}>
              <Col className="pt-2">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between mx-4">
                    <FormLabel className="fw-bold">이용서비스</FormLabel>
                    <div className="d-flex w-75 gap-3">
                      <span>이용중</span>
                      <Form.Check
                        type="switch"
                        checked={this.state.isActive}
                        onChange={(e) => {
                          this.setState({
                            isActive: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 mx-4">
                    <div className="w-75">
                      <ServiceSelect
                        style={{ width: '100%', height: 38 }}
                        size="large"
                        disabled={!this.state.isActive}
                        value={serviceType}
                        options={ServiceOptions}
                        onChange={(e) => form.setValue('serviceType', e)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">대표번호</FormLabel>
                    <div className="w-75">
                      <Input
                        store={form}
                        label={`대표번호`}
                        name={`companyTel`}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-5 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">담당자</FormLabel>
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <span>아이디</span>
                      <div className="w-75">
                        <Input
                          store={form}
                          label={`아이디`}
                          name={`user.loginId`}
                          placeholder={`이메일 형식으로 입력해 주세요.`}
                          validations={[
                            new Validation(
                              /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                              `이메일 형식으로 입력해 주세요.`,
                            ),
                          ]}
                          validOnBlur
                          style={{
                            borderRadius: isNew && '0.375rem 0 0 0.375rem',
                          }}
                          button={
                            isNew && (
                              <Button
                                variant={`outline-primary`}
                                onClick={() =>
                                  this.validCheck(form.valueMap.get('loginId'))
                                }
                              >
                                중복확인
                              </Button>
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3 mx-4">
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <span>이름</span>
                      <div className="w-75">
                        <Input store={form} label={`이름`} name={`user.name`} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3 mx-4">
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <span>연락처</span>
                      <div className="w-75">
                        <Input
                          store={form}
                          label={`연락처`}
                          name={`user.tel`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className={`pt-5`}></Col>
            </FormGroup>
          </Form>
        </Panel>
        <ConfirmDialog />
      </FrameWrap>
    );
  }
}

export default CompanyDetailView;
