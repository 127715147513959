import React from 'react';
import Cell from './Cell.js';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'react-bootstrap';
import Styles from './DataGrid.module.scss';
import DataGridStore from '../../store/DataGridStore.js';
/**
 * 데이터그리드 행
 */
@observer
class Row extends React.Component {
  /**
   * 셀이 표현할 데이터를 key 배열로 배열이 빌 때까지 차례대로 참조하여 가져온다.
   *
   * @param keys
   * @param m
   * @returns {*}
   */
  getCellValueByKeys(keys, m) {
    if (m === undefined || m == null) {
      return null;
    } else if (keys.length === 0) {
      return m;
    } else {
      let [head, ...tail] = keys;
      return this.getCellValueByKeys(tail, m[head]);
    }
  }

  render() {
    let {
      store,
      onClick,
      rowData,
      columns,
      highlightClass,
      isHighlightFn,
      isCheckable,
    } = this.props;
    let key = rowData[store.primaryKey];
    let statusMap = store.dataSet?.statusMap?.get(key) || new Map();
    let status = statusMap?.get('status');
    const classNames = [
      isHighlightFn && isHighlightFn(rowData) ? highlightClass : '',
    ];
    return (
      <tr
        className={`${classNames.join(' ')} ${Styles.TableTr}`}
        onClick={() => {
          onClick && onClick(rowData);
        }}
      >
        {isCheckable && (
          <td className={`${Styles.Cell}`}>
            <Form.Check
              checked={status?.isChecked}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                store.toggleCheckedRows(rowData, e.target.checked);
              }}
            />
          </td>
        )}
        {columns.map((column, idx) => {
          let { id, printFunction, options } = column;
          let errorMessageMap = statusMap?.get('errorMessageMap');
          let errorMessage = errorMessageMap?.get(id);
          let cellData = this.getCellValueByKeys(id?.split('.'), rowData);
          return (
            <Cell
              key={`${key}-${id}-${idx}`}
              id={id}
              store={store}
              errorMessage={errorMessage}
              cellData={cellData}
              rowData={rowData}
              printFunction={printFunction}
              status={status}
              column={column}
              options={options}
            />
          );
        })}
      </tr>
    );
  }
}

Row.propTypes = {
  store: PropTypes.instanceOf(DataGridStore),
  /**
   * 컬럼
   */
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  keyColumn: PropTypes.any.isRequired,
  /**
   * 행의 데이터
   */
  rowData: PropTypes.object,
  isHighlightFn: PropTypes.func,
  highlightClass: PropTypes.string,
  onClick: PropTypes.func,
  isCheckable: PropTypes.bool,
};

export default Row;
