import React, { Component } from 'react';
import Styles from './ListLabel.module.scss';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

class ListLabel extends Component {
  render() {
    const { label, onClick, children, collapsed } = this.props;
    return (
      <div className={Styles.ListLabel} onClick={onClick || noop}>
        <div className={Styles.Text}>
          {collapsed ? label.substr(0, 2) : label}
          {children}
        </div>
      </div>
    );
  }
}

ListLabel.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default ListLabel;
