import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Styles from './DoubleArrowIcon.module.scss';
import { MdKeyboardDoubleArrowLeft } from 'react-icons/md';

class DoubleArrowIcon extends Component {
  render() {
    const { collapsed, className, ...rest } = this.props;
    return (
      <div
        className={`${Styles.DoubleArrowIcon} ${collapsed ? Styles.Collapsed : ''} ${className ? className : ''}`}
        {...rest}
      >
        <MdKeyboardDoubleArrowLeft color={'#9199A1'} />
      </div>
    );
  }
}

DoubleArrowIcon.propTypes = {
  collapsed: PropTypes.bool,
};

export default DoubleArrowIcon;
