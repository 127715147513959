import React from 'react';
import Styles from './IncomingMessage.module.scss';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Back, PlayCircle } from 'iconsax-react';
import Message from './Message.js';
import MessageContent from './MessageContent.js';
import Avatar from '../avatar/Avatar.js';

@observer
class IncomingMessage extends React.Component {
  audioMessage;

  replyMessage(message) {
    let { store } = this.props;
    store.replyMessage = message;
  }

  toLittleEndianBytes = (value, bytes) => {
    let result = '';
    for (let i = 0; i < bytes; i++) {
      result += String.fromCharCode((value >> (i * 8)) & 0xff);
    }
    return result;
  };

  playWavBlobOnce(blob) {
    console.log('playWavBlobOnce');
    var audioContext = new (window.AudioContext || window.webkitAudioContext)();
    var fileReader = new FileReader();
    fileReader.onload = function () {
      var arrayBuffer = this.result;
      audioContext.decodeAudioData(arrayBuffer, function (buffer) {
        var source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start(0); // 이 부분이 start 대신 noteOn으로 변경됩니다.
      });
    };
    fileReader.readAsArrayBuffer(blob);
  }

  playChunk(message) {
    if (message.audioChunk != undefined) {
      let bytes = atob(message.audioChunk);
      const byteNumbers = new Array(bytes.length);

      for (let i = 0; i < bytes.length; i++) {
        byteNumbers[i] = bytes.charCodeAt(i);
      }

      let chunks = new Uint8Array(byteNumbers);

      const header =
        'RIFF' +
        this.toLittleEndianBytes(chunks.length + 36, 4) + // 파일 크기
        'WAVE' +
        'fmt ' +
        this.toLittleEndianBytes(16, 4) + // fmt chunk 크기
        this.toLittleEndianBytes(7, 2) + // 포맷 코드 (6: mu-law)
        this.toLittleEndianBytes(1, 2) + // 채널 수 (모노)
        this.toLittleEndianBytes(8000, 4) + // 샘플링 속도
        this.toLittleEndianBytes(8000 * 1 * 1, 4) + // 바이트/초
        this.toLittleEndianBytes(1 * 8, 2) + // 블록 크기 (1채널 * 8비트)
        this.toLittleEndianBytes(8, 2) + // 샘플 크기 (비트당 샘플링 크기)
        'data' +
        this.toLittleEndianBytes(chunks.length, 4); // 데이터 크기

      // WAV 파일 Blob 생성
      const wavBlob = new Blob([header, chunks], { type: 'audio/wav' });

      this.playWavBlobOnce(wavBlob);

      // WAV 파일 다운로드 URL 생성
      //   const wavUrl = URL.createObjectURL(wavBlob);
    }
    //playWavBlobOnce(blob);
  }

  render() {
    let { message, isSameUser, store } = this.props;
    this.audioMessage = message;
    let {
      sender,
      createdAt,
      focus = false,
      notConfirmCount = 0,
      replyMessage,
      content,
    } = message;
    return (
      <div
        className={`${Styles.Container} ${focus ? Styles.Focus : ''}`}
        onAnimationEnd={() => {
          store.messageMap.set(message.id, { ...message, focus: false });
        }}
      >
        <div className={Styles.Avatar}>
          {!isSameUser && (
            <Avatar
              className={Styles.AvatarImg}
              type={sender?.type}
              src={sender?.avatar?.fullUrl}
              seed={sender?.id}
            />
          )}
        </div>
        <div className={Styles.Content}>
          {!isSameUser && <div className={Styles.Sender}>{sender?.name}</div>}
          <div className={Styles.ContentInner}>
            <Message>
              <MessageContent
                content={content}
                store={store}
                messageKey={message.id}
                isIncoming={true}
                replyMessage={replyMessage}
              />
            </Message>
            {store.channel.status !== 'CLOSED' && (
              <div className={Styles.MessageOption}>
                {
                  notConfirmCount > 0 && (
                    <div className={Styles.NotConfirmCount}>
                      {notConfirmCount}
                    </div>
                  )
                  // :
                  // <MdDoneAll color={'#009688'}/>
                }
                <Button size={'sm'} className={Styles.Button}>
                  <Back
                    className={Styles.Icon}
                    onClick={() => {
                      this.replyMessage(message);
                    }}
                  />
                  {message?.audioChunk && (
                    <PlayCircle
                      className={`${Styles.Icon}`}
                      variant="Bold"
                      onClick={() => {
                        this.playChunk(message);
                      }}
                    />
                  )}
                </Button>
              </div>
            )}
          </div>
          <div className={Styles.Date}>{moment(createdAt).format('LT')}</div>
        </div>
      </div>
    );
  }
}

export default IncomingMessage;
