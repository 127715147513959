import React, {Component} from 'react';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import Styles from './BranchMemoView.module.scss';
import axios from 'axios';
import {FormStore, Input, Validation} from '../../components/validation';
import moment from 'moment';
import appStore from '../../store/AppStore';
import MultiFormSelect from '../../components/input/MultiFormSelect';
import RadioGroup from '../../components/input/RadioGroup';
import {toast} from 'react-toastify';
import {confirmDialogStore, ConfirmDialog} from "@qbot-chat/qbot-uikit/modal";
import {Warning2} from 'iconsax-react';
import ModalBase from "../../components/modal/ModalBase";
import {DatePicker} from "antd";
const { RangePicker } = DatePicker;
import dayjs from "dayjs";
import {ACCESS_RIGHTS} from "../../const/AppConst";
import {USER_TYPE} from "@qbot-chat/qbot-uikit";
class BranchMemoDialogStore {

    @observable
    show = false;

    id;

    onSave;

    branchMemoFormStore = new FormStore();

    constructor() {
        makeObservable(this);
        this.branchMemoFormStore.clear({type: 'pin'})
    }

    @action.bound
    open(id, onSave) {
        this.id = id;
        this.onSave = onSave;

        if (id) {
            axios.get(`/branch/pin/memo/${id}`)
                .then(({data}) => {
                    this.branchMemoFormStore.values = data;
                });
        }

        this.show = true;
    }

    @action.bound
    delete() {
        confirmDialogStore.openRemove(
            <Warning2/>,
            '삭제 하시겠습니까?',
            () => {
                axios.post(`/branch/memo/delete/${this.id}`)
                    .then(() => {
                        toast.info('메모를 삭제했습니다.')
                        this.onSave && this.onSave();
                        this.close();
                    });
            }
        );
    }

    @action.bound
    close() {
        this.show = false;
        this.id = undefined;
        this.branchMemoFormStore.clear({type: 'pin'});
    }

    @action.bound
    save() {

        if(!this.branchMemoFormStore.doCheckValid()) {
            return;
        }

        axios.post('/branch/pin/memo/write', {...this.branchMemoFormStore.values})
            .then(() => {
                toast.info('점포 공감을 저장하였습니다.');
                this.onSave && this.onSave();
            })
            .finally(() => {
                this.close();
            })

    }
}

const branchMemoDialogStore = new BranchMemoDialogStore();


@observer
class BranchMemoDialog extends Component {

    handleClose() {
        branchMemoDialogStore.close();
    }

    componentDidMount() {
    }


    render() {
        const {id, show, branchMemoFormStore} = branchMemoDialogStore;
        const {creator, createdAt, updatedAt} = branchMemoFormStore.values;
        return (
            <ModalBase
                   size={'lg'}
                   show={show}
                   onHide={this.handleClose.bind(this)}
            >
                <Modal.Header closeButton>
                    <div className={Styles.Header}>
                        {
                            id ? '점포 핀 메모 수정' : '새 핀 메모 작성'
                        }
                    </div>
                </Modal.Header>
                <Modal.Body className={Styles.Body}>
                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            게시유형
                        </Col>
                        <Col md={10} sm={10}>
                            <RadioGroup value={branchMemoFormStore.valueMap.get('type')}
                                        onChange={e => {
                                            branchMemoFormStore.setValue('type', e.target.value);
                                        }}
                                        marginRightRem={1}
                            >
                                <Form.Check inline type="radio" label="지점별" value="pin"/>
                                <Form.Check inline type="radio" label="브랜드별" value="pin_brand"/>
                            </RadioGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            게시점포
                        </Col>
                        <Col md={10} sm={10}>
                            <MultiFormSelect store={branchMemoFormStore} name="pinBranches" isMulti options={appStore.conciseBranchesOptions}
                                             getTransform={ branch => ({value: branch?.id, label: branch.name}) }
                                             setTransform={ option => ({id: option.value, name: option.label}) }
                                             validations={[
                                                 new Validation(branch => {
                                                     return branchMemoFormStore.valueMap.get('type') !== 'pin' || (branch && branch.length > 0)
                                                 }, '점포를 한 개 이상 선택해 주세요.' )
                                             ]}
                                             validOnBlur
                                             isDisabled={branchMemoFormStore.valueMap.get('type') !== 'pin'}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            게시브랜드
                        </Col>
                        <Col md={10} sm={10}>
                            <MultiFormSelect store={branchMemoFormStore} name="pinBrands" isMulti options={appStore.brandOptions}
                                             getTransform={ brand => ({value: brand?.id, label: brand.name}) }
                                             setTransform={ option => ({id: option.value, name: option.label}) }
                                             validations={[
                                                 new Validation(branch => {
                                                     return branchMemoFormStore.valueMap.get('type') !== 'pin_brand' || (branch && branch.length > 0)
                                                 }, '브랜드를 한 개 이상 선택해 주세요.' )
                                             ]}
                                             validOnBlur
                                             isDisabled={branchMemoFormStore.valueMap.get('type') !== 'pin_brand'}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            게시기간
                        </Col>
                        <Col md={10} sm={10}>
                            <RangePicker className={'me-3 mt-2 mb-2'} popupStyle={{ zIndex: '999999' }}
                                         value={[branchMemoFormStore.valueMap.get('beginAt') ?
                                             dayjs(branchMemoFormStore.valueMap.get('beginAt')) :
                                             undefined
                                             ,
                                             branchMemoFormStore.valueMap.get('untilAt') ?
                                             dayjs(branchMemoFormStore.valueMap.get('untilAt')) :
                                             undefined
                                         ]}
                                         onChange={(dates) => {
                                             branchMemoFormStore.setValue('beginAt', dates ? dates[0] : null);
                                             branchMemoFormStore.setValue('untilAt', dates ? dates[1] : null);
                                         }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                        <Col md={2} sm={2} style={{display: 'flex', alignItems: 'center'}}>
                            메모
                        </Col>
                        <Col md={10} sm={10}>
                            <Input store={branchMemoFormStore} name="notes" size="md"
                                   validations={[new Validation(/^.+$/, '메모를 입력해주세요.')]}
                            />
                        </Col>
                    </Form.Group>
                    {
                        id &&
                        <>
                            <Form.Group as={Row} className="mb-4">
                                <Col md={2} sm={2}>
                                    <Form.Label>생성자</Form.Label>
                                </Col>
                                <Col md={10} sm={10}>
                                    <div>{creator?.name}</div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4">
                                <Col md={2} sm={2}>
                                    <Form.Label>생성일</Form.Label>
                                </Col>
                                <Col md={4} sm={4}>
                                    <div>{createdAt && moment(createdAt).format('YYYY-MM-DD')}</div>
                                </Col>
                                <Col md={2} sm={2}>
                                    <Form.Label>수정일</Form.Label>
                                </Col>
                                <Col md={4} sm={4}>
                                    <div>{updatedAt && moment(updatedAt)?.format('YYYY-MM-DD')}</div>
                                </Col>
                            </Form.Group>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        id && ACCESS_RIGHTS[USER_TYPE.AGENT_LEADER].includes(appStore.type) &&
                        <div className="flex-grow-1">
                            <Button variant="outline-danger" onClick={branchMemoDialogStore.delete}>삭제</Button>
                        </div>
                    }

                    <Button variant="outline-secondary" onClick={branchMemoDialogStore.close}>닫기</Button>
                    {
                        ACCESS_RIGHTS[USER_TYPE.AGENT_LEADER].includes(appStore.type) &&
                        <Button variant="outline-primary"
                                onClick={branchMemoDialogStore.save}
                        >
                            저장
                        </Button>
                    }
                </Modal.Footer>
                <ConfirmDialog/>
            </ModalBase>
        );
    }

}

export {BranchMemoDialog as default, branchMemoDialogStore};
