import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { navigate } from '../../helper/HistoryHelper';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import TitlePanel from '../../components/layout/TitlePanel';
import FrameWrap from '../../components/layout/FrameWrap';
import { makeObservable, observable } from 'mobx';

const botMessageTemplateDataGridStore = new DataGridStore({
  fetchUrl: `/bot/message/template/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`createdAt`, Sort.SORT_DIRECTION.DESC),
});

@observer
class BotMessageTemplateManageListView extends Component {
  @observable
  SearchKeyword = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    botMessageTemplateDataGridStore.refresh();
  }

  componentWillUnmount() {
    botMessageTemplateDataGridStore.searchCondition.clear();
  }

  search = () => {
    botMessageTemplateDataGridStore.searchCondition.set(
      'keyword',
      this.searchKeyword,
    );
    botMessageTemplateDataGridStore.fetch();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      botMessageTemplateDataGridStore.page = 1;
      this.search();
    }
  };

  render() {
    let store = botMessageTemplateDataGridStore;
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={'챗봇 메시지'} />
              <div>
                <Button
                  variant={'outline-primary'}
                  onClick={() => {
                    navigate(`/main/bot/message/template/new`);
                  }}
                >
                  + 챗봇 메시지 추가
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
            <FormGroup>
              <Col className={`d-flex flex-column`}>
                <div className={'d-flex'}>
                  <Col md={{ span: 12, offset: 0 }}>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        제목, 인텐트ID
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="제목 또는 인텐트ID로 검색"
                        onChange={(e) => {
                          this.searchKeyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={'outline-secondary'}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className={'flex-default'}>
          <DataGrid
            store={store}
            keyColumn={`id`}
            columns={[
              <Header
                key={`index`}
                id={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,
              <Header
                id={`intentId`}
                key={`intentId`}
                name={`인텐트ID`}
                width={{ min: 70, current: 80, max: 100 }}
                printFunction={(id) => (
                  <Badge pill bg="secondary" className="IdBadge">
                    {id}
                  </Badge>
                )}
              />,
              <Header
                key={`title`}
                id={`title`}
                name={`제목`}
                width={{ min: 150, current: 200, max: 500 }}
                printFunction={(title) => <div>{title}</div>}
              />,
              <Header
                key={`brandId`}
                id={`brandId`}
                name={`브랜드키`}
                width={{ min: 70, current: 80, max: 100 }}
                printFunction={(brandId) => <div>{brandId}</div>}
              />,
              <Header
                key={`isActive`}
                id={`isActive`}
                name={`상태`}
                width={{ min: 70, current: 80, max: 100 }}
                printFunction={(isActive) => (
                  <Badge bg={isActive ? 'success' : 'danger'}>
                    {isActive ? '사용' : '미사용'}
                  </Badge>
                )}
              />,
              <Header
                id="createdAt"
                key="createdAt"
                sortable={true}
                name={`생성 일시`}
                className={`justify-content-end`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
            ]}
            onRowClick={(rowData) => {
              navigate(`/main/bot/message/template/detail/${rowData.id}`);
            }}
            isCheckable={true}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export {
  BotMessageTemplateManageListView as default,
  botMessageTemplateDataGridStore,
};
